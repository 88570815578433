import React, { useEffect, useState } from 'react';
import axios from 'axios';
import configData from '../Config.json';

import './CountryEditor.css';

//20240505

function CountryEditor({ state, setState }) {
    const [countries, setCountries] = useState([]);

    const [option, setOption] = useState(false);
    const [change, setChange] = useState(false);

    useEffect(() => {
        console.log('Trying to get countries');
        axios.post(configData.SECUREURL + configData.ADMINGETFULLCOUNTRIES, {}, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
        }).then(res => {
            console.log('Get countries data received')
            console.log(res.data)
            if (res.data instanceof Array) {
                setCountries(res.data)
            }
        })
    }, [change])

    const toggleCountry = (countryid) => {
        console.log('Toggle country');
        const data = {
            countryid: countryid
        }
        axios.post(configData.SECUREURL + configData.ADMINTOGGLECOUNTRY, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Toggle country data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) setChange(!change)
            }
        }).catch(() => {
        }).finally(() => {
        })
    }

    function AddCountry({ setOption }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');
        const [dir, setDir] = useState('');
        const [table, setTable] = useState('');
        const [defaultdistrict, setDefaultdistrict] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const addCountry = (id, name, dir, table, defaultdistrict) => {
            console.log('Trying to add country');
            setResult(null);
            setLoading(true);

            const data = {
                id: id,
                name: name,
                dir: dir,
                table: table,
                defaultdistrict: defaultdistrict
            };
            axios.post(configData.SECUREURL + configData.ADMINADDCOUNTRY, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Add country data received');
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setChange(!change)
                        setOption(null)
                    }
                    else {
                        setResult('Could not add country');
                    }
                }
                else {
                    setResult('Could not add country');
                }
            }).catch(() => {
                setResult('Could not add country');
            }).finally(() => {
                setLoading(false)
            })
        }

        return (
            <div className='countryeditor_modal'>
                <div className='countryeditor_modaltitle'>Add Country</div>
                <div className='countryeditor_modaltitleinputlabel'>Country ID</div>
                <input className='countryeditor_modaltitleinput' type='text' value={id} onChange={e => setId(e.target.value)} />
                <div className='countryeditor_modaltitleinputlabel'>Country Name</div>
                <input className='countryeditor_modaltitleinput' type='text' value={name} onChange={e => setName(e.target.value)} />
                <div className='countryeditor_modaltitleinputlabel'>Country Dir</div>
                <input className='countryeditor_modaltitleinput' type='text' value={dir} onChange={e => setDir(e.target.value)} />
                <div className='countryeditor_modaltitleinputlabel'>Country Table</div>
                <input className='countryeditor_modaltitleinput' type='text' value={table} onChange={e => setTable(e.target.value)} />
                <div className='countryeditor_modaltitleinputlabel'>Default District</div>
                <input className='countryeditor_modaltitleinput' type='text' value={defaultdistrict} onChange={e => setDefaultdistrict(e.target.value)} />
                <div className='countryeditor_modalresult'>{result}</div>
                <button className='countryeditor_modalbutton' disabled={loading} onClick={() => addCountry(id, name, dir, table, defaultdistrict)}>
                    {loading ? 'Adding Country...' : 'Add Country'}
                </button>
                <button className='countryeditor_modalbutton' onClick={() => setOption()}>Cancel</button>
            </div>
        )
    }

    function EditCountry({ country }) {
        const [id, setId] = useState(country.id);
        const [name, setName] = useState(country.name);
        const [dir, setDir] = useState(country.dir);
        const [table, setTable] = useState(country.table);
        const [defaultdistrict, setDefaultdistrict] = useState(country.defaultdistrict);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const updateCountry = (id, name, dir, table, defaultdistrict) => {
            console.log('Trying to update country');
            setResult(null);
            setLoading(true);

            const data = {
                id: id,
                name: name,
                dir: dir,
                table: table,
                defaultdistrict: defaultdistrict
            };
            axios.post(configData.SECUREURL + configData.ADMINUPDATECOUNTRY, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Update country data received');
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setChange(!change)
                        setOption(null)
                    }
                    else {
                        setResult('Could not update country');
                    }
                }
                else {
                    setResult('Could not update country');
                }
            }).catch(() => {
                setResult('Could not update country');
            }).finally(() => {
                setLoading(false)
            })
        }

        return (
            <div className='countryeditor_modal'>
                <div className='countryeditor_modaltitle'>Edit Country</div>
                <div className='countryeditor_modaltitleinputlabel'>Country ID</div>
                <input className='countryeditor_modaltitleinput' type='text' value={id} onChange={e => setId(e.target.value)} />
                <div className='countryeditor_modaltitleinputlabel'>Country Name</div>
                <input className='countryeditor_modaltitleinput' type='text' value={name} onChange={e => setName(e.target.value)} />
                <div className='countryeditor_modaltitleinputlabel'>Country Dir</div>
                <input className='countryeditor_modaltitleinput' type='text' value={dir} onChange={e => setDir(e.target.value)} />
                <div className='countryeditor_modaltitleinputlabel'>Country Table</div>
                <input className='countryeditor_modaltitleinput' type='text' value={table} onChange={e => setTable(e.target.value)} />
                <div className='countryeditor_modaltitleinputlabel'>Default District</div>
                <input className='countryeditor_modaltitleinput' type='text' value={defaultdistrict} onChange={e => setDefaultdistrict(e.target.value)} />
                <div className='countryeditor_modalresult'>{result}</div>
                <button className='countryeditor_modalbutton' disabled={loading} onClick={() => updateCountry(id, name, dir, table, defaultdistrict)}>
                    {loading ? 'Updating Country...' : 'Update Country'}
                </button>
                <button className='countryeditor_modalbutton' onClick={() => setOption()}>Cancel</button>
            </div>
        )
    }
    function RemoveCountry({ country }) {
        const [id, setId] = useState(country.id);
        const [name, setName] = useState(country.name);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const removeCountry = (id) => {
            console.log('Trying to remove country');
            setResult(null);
            setLoading(true);

            const data = {
                countryid: id
            };
            axios.post(configData.SECUREURL + configData.ADMINREMOVECOUNTRY, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Remove country data received');
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setChange(!change)
                        setOption(null)
                    }
                    else {
                        setResult('Could not remove country');
                    }
                }
                else {
                    setResult('Could not remove country');
                }
            }).catch(() => {
                setResult('Could not remove country');
            }).finally(() => {
                setLoading(false)
            })
        }

        return (
            <div className='countryeditor_modal'>
                <div className='countryeditor_modaltitle'>Confirm Remove ({name}) ? </div>
                <div className='countryeditor_modalresult'>{result}</div>
                <button className='countryeditor_modalbutton' onClick={() => removeCountry(id)}>Remove</button>
                <button className='countryeditor_modalbutton' onClick={() => setOption()}>Cancel</button>
            </div>
        )
    }

    function CountryOptions({ country }) {
        return (
            <div className='countryeditor_modal'>
                <button className='countryeditor_modalbutton' onClick={() => setOption(<AddCountry />)}>Add New Country</button>
                <button className='countryeditor_modalbutton' onClick={() => setOption(<RemoveCountry country={country} />)}>Remove Country</button>
                <button className='countryeditor_modalbutton' onClick={() => setOption(<EditCountry country={country} />)}>Edit Country</button>
                <button className='countryeditor_modalbutton' onClick={() => setOption()}>Cancel</button>
            </div>
        )
    }

    return (
        <div className='countryeditor'>
            <table className='countryeditor_table'>
                <thead><tr><th>Id</th><th>Name</th><th>Dir</th><th>Table</th><th>DefaultDistrict</th><th>Status</th><th>Option</th></tr></thead>
                <tbody>
                    {
                        countries.map((country, index) => {
                            return (
                                <tr key={'country' + index}>
                                    <td>{country.id}</td>
                                    <td>{country.name}</td>
                                    <td>{country.dir}</td>
                                    <td>{country.table}</td>
                                    <td>{country.defaultdistrict}</td>
                                    <td>
                                        <button
                                            className='countryeditor_tabletogglebutton'
                                            onClick={() => toggleCountry(country.id)}
                                        >
                                            {country.active === 1 ? 'ON' : 'OFF'}
                                        </button>
                                    </td>
                                    <td><div className='countryeditor_tabletoggleoptionsbutton' onClick={() => setOption(<CountryOptions country={country} />)}>...</div></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

            {option}
        </div>
    )
}

export default CountryEditor;