import React, { useState, useEffect } from 'react';
import axios from 'axios';
import configData from './Config.json';
import Chat from './Chat';

import placeholderimage from './Assets/profile/default.jpg';

import './Profile.css';

//revision 20240418

function Profile({ state, setState, userid }) {

    const [profileuser, setProfileUser] = useState({ userid: '', username: '', userbiography: '', userprofilepicture: '' });

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(false);

        const data = {
            userid: userid
        };

        axios.post(configData.SECUREURL + configData.GETPROFILE, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            if (res.data instanceof Object) {
                setProfileUser(res.data)
            }
        }).catch((err) => {
            console.log(err.response?.data?.message || err.message || 'Network error');
        }).finally(() => {
            setLoaded(true)
        })
    }, [])

    const openChat = (profile) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Chat state={state} setState={setState} userid={profile.userid} userprofilepic={profileuser.userprofilepicture} />, modaltype: 'large', modaltitle: profile.username }))
    }

    return (
        <div className='profile'>
            <div className='profile_container'>
                <div className='profile_username'>{profileuser.username}</div>
                {
                    loaded ?
                        <img className='profile_userprofilepicture' src={profileuser.userprofilepicture} alt='Profile Picture' />
                        :
                        <img className='profile_userprofilepicture' src={placeholderimage} alt='Profile Picture' />
                }
                <div className='profile_userbiocontainer'>
                    <div className='profile_userbio'>
                        {profileuser.userbiography}
                    </div>
                </div>
                {
                    state.user.userid && (state.user.userid !== userid) &&
                    <div className='profile_chatbuttoncontainer'>
                        <button
                            className='profile_chatbutton'
                            onClick={() => openChat(profileuser)}
                            disabled={!loaded}
                        >
                            Chat
                        </button>
                    </div>
                }
            </div>
        </div>
    )
}

export default Profile;