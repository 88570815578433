import React, { useEffect, useRef, useState } from 'react';
import Compressor from 'compressorjs';

import './FileUploader.css';

function FileUploader({ previewImages, previewVideos, media, setMedia, setImagesToRemove, setVideosToRemove }) {
    const [previewimages, setPreviewimages] = useState([]);
    const [previewvideos, setPreviewvideos] = useState([]);

    const [imagestoremove, setImagestoremove] = useState([]);
    const [videostoremove, setVideostoremove] = useState([]);

    const [isDragging, setIsDragging] = useState(false);
    const [progress, setProgress] = useState(0);
    const [result, setResult] = useState('');
    const [error, setError] = useState('');

    const fileInputRef = useRef(null);
    const totalSize = media.reduce((acc, file) => acc + file.size, 0);
    const totalSizeInMB = (totalSize / 1000000).toFixed(2);

    useEffect(() => {
        setPreviewimages(previewImages);
        setPreviewvideos(previewVideos);
    }, [previewImages, previewVideos]);

    useEffect(() => {
        if (typeof setImagesToRemove === 'function') {
            setImagesToRemove(imagestoremove);
        }
    }, [imagestoremove, setImagesToRemove]);

    useEffect(() => {
        if (typeof setVideosToRemove === 'function') {
            setVideosToRemove(videostoremove);
        }
    }, [videostoremove, setVideosToRemove]);

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const fileList = event.dataTransfer.media || event.dataTransfer.files;
        const totalFiles = fileList.length;
        let completedFiles = 0;
        const maxSize = 5 * 1024 * 1024;

        const updateProgress = () => {
            const progress = Math.floor((completedFiles / totalFiles) * 100);
            console.log(`Progress: ${progress}%`);
        };

        const compressedFiles = Array.from(fileList).map(async (file) => {
            if (file.type.startsWith('image/')) {
                console.log('image detected');
                return new Promise((resolve) => {
                    new Compressor(file, {
                        success: (result) => {
                            completedFiles++;
                            console.log('compress complete');
                            updateProgress();
                            resolve(result);
                        },
                        error: (err) => {
                            console.log(err.message);
                            completedFiles++;
                            console.log('compress error');
                            updateProgress();
                            resolve(file);
                        },
                    });
                });
            } else {
                console.log('not a media file, ignoring');
                completedFiles++;
                updateProgress();
                return null;
            }
        });

        Promise.all(compressedFiles)
            .then((compressedFiles) => {
                const filteredFiles = compressedFiles.filter((file) => file !== null);
                setMedia([...media, ...filteredFiles]);
                setResult([...media, ...filteredFiles].length + ' files');
            })
            .catch((error) => {
                console.log(error);
                const filteredFiles = compressedFiles.filter((file) => file !== null);
                setMedia([...media, ...filteredFiles]);
                setResult([...media, ...filteredFiles].length + ' files');
            });
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        setIsDragging(false);
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleFileInputChange = async (event) => {
        const selectedFiles = Array.from(event.target.files);
        const totalFiles = selectedFiles.length;
        let completedFiles = 0;
        const maxSize = 5 * 1024 * 1024; // 5MB

        const updateProgress = () => {
            const progress = Math.floor((completedFiles / totalFiles) * 100);
            console.log(`Progress: ${progress}%`);
        };

        const compressedFiles = await Promise.all(
            selectedFiles.map(async (file) => {
                if (file.type.startsWith('image/')) {
                    console.log('image detected')
                    return new Promise((resolve) => {
                        new Compressor(file, {
                            success: (result) => {
                                completedFiles++;
                                console.log('compress complete')
                                updateProgress();
                                resolve(result);
                            },
                            error: (err) => {
                                console.log(err.message);
                                completedFiles++;
                                console.log('compress error')
                                updateProgress();
                                resolve(file);
                            },
                        });
                    });
                }
                else {
                    console.log('not a media file, ignoring');
                    completedFiles++;
                    updateProgress();
                    return null;
                }
            })
        );

        const filteredFiles = compressedFiles.filter((file) => file && file.size);
        setProgress(100);
        setMedia([...media, ...filteredFiles]);
        setResult([...media, ...filteredFiles].length + ' files');
    };


    const removePreviewImage = (image, index) => {
        const newPreviewImages = [...previewimages];
        newPreviewImages.splice(index, 1);
        setPreviewimages(newPreviewImages);

        console.log(image)
        const filenameWithExtension = image.substring(image.lastIndexOf('/') + 1);
        console.log(filenameWithExtension);

        setImagestoremove([...imagestoremove, filenameWithExtension]);
        setImagesToRemove(imagestoremove);
    };

    const removePreviewVideo = (video, index) => {
        const newPreviewVideos = [...previewvideos];
        newPreviewVideos.splice(index, 1);
        setPreviewvideos(newPreviewVideos);

        console.log(video)
        const filenameWithExtension = video.substring(video.lastIndexOf('/') + 1);
        console.log(filenameWithExtension);

        setVideostoremove([...videostoremove, filenameWithExtension]);
        setVideosToRemove(videostoremove);
    };

    const removeMediaFile = (index) => {
        const newFiles = [...media];
        newFiles.splice(index, 1);
        setMedia(newFiles)
        setResult(newFiles.length + ' files');
        setError('');
    }

    return (
        <>
            <div
                className={`fileuploader ${isDragging ? 'fileuploader_draging' : ''}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={handleClick}
            >
                <svg viewBox="0 -5 32 52" className="fileuploader_svg"><g><polyline points="1 19 1 31 31 31 31 19"></polyline><polyline points="8 9 16 1 24 9"></polyline><line x1="16" x2="16" y1="1" y2="25"></line></g></svg>
                <div className="fileuploader_text">Drag and Drop Images Here to Upload</div>
                <div className="fileuploader_or">Or</div>

                <button className='fileuploader_button'>Select Images to Upload</button>
                <input type="file" multiple accept="image/*,video/*" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileInputChange} />
                <div className='fileuploader_progress'>Processing progress: {progress.toFixed(2)}%</div>
                <div className='fileuploader_resultContainer'>
                    <span className='fileuploader_resultText'>{result}</span>
                </div>
                <div className='fileuploader_filenames'>
                    {
                        media.map((file, index) => {
                            const isLastFile = index === media.length - 1;
                            const comma = media.length > 1 && !isLastFile ? ", " : "";
                            return (
                                <span key={index} className='fileuploader_filename'>{file.name} {file.size.toLocaleString("en-US")} bytes{comma}</span>
                            )
                        })
                    }
                    <div className='fileuploader_totalSize'>Total size: {totalSizeInMB} MB</div>
                </div>
            </div>

            {
                previewimages instanceof Array && previewimages.length !== 0 && previewimages.map((image, index) => {
                    return (
                        <div key={'fileuploader_imageContainer' + index} className='fileuploader_imageContainer'>
                            <img
                                className='fileuploader_image'
                                src={image}
                                alt={'imagespreview' + index}
                            />
                            <span className='fileuploader_removeButton' onClick={() => removePreviewImage(image, index)}>x</span>
                        </div>
                    )
                })
            }

            {
                previewvideos instanceof Array && previewvideos.length !== 0 && previewvideos.map((video, index) => {
                    return (
                        <div key={'fileuploader_videoContainer' + index} className='fileuploader_videoContainer'>
                            <video
                                className='fileuploader_video'
                                src={video}
                            />
                            <span className='fileuploader_removeButton' onClick={() => removePreviewVideo(video, index)}>x</span>
                        </div>

                    )
                })
            }

            <div>
                {
                    media.map((file, index) => (
                        file.type.startsWith('image') ? (
                            <div key={'fileuploader_imageContainer' + index} className='fileuploader_imageContainer'>
                                <img
                                    className='fileuploader_image'
                                    src={URL.createObjectURL(file)}
                                    alt={file.name}
                                />
                                < span className='fileuploader_removeButton' onClick={() => removeMediaFile(index)}>x</span>
                            </div>
                        ) : (
                            <div key={'fileuploader_videoContainer' + index} className='fileuploader_videoContainer'>
                                <video
                                    className='fileuploader_video'
                                    src={URL.createObjectURL(file)}
                                    alt={file.name}
                                    controls
                                />
                                < span className='fileuploader_removeButton' onClick={() => removeMediaFile(index)}>x</span>
                            </div>
                        )
                    ))
                }
            </div>
            <div className='fileuploader_errorContainer'>
                <span className='fileuploader_errorText'>{error}</span>
            </div>
        </>
    )
}

export default FileUploader;