import React, { useEffect, useState } from 'react';
import axios from 'axios';
import configData from '../Config.json';

import './UserEdit.css';

//Revision 15-5-2023 CSS

function UserEdit({ state, setState, userId }) {
    const [profilepicture, setProfilepicture] = useState(null);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [biography, setBiography] = useState('');
    const [selectedtype, setSelectedtype] = useState(0);
    const [selectedstatus, setSelectedStatus] = useState(0);
    const [result, setResult] = useState();
    const [usertypes, setUsertypes] = useState([]);
    const [userstatuses, setUserStatuses] = useState([]);
    const [confirmationOpen, setConfirmationOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log('Getting admin settings')
        axios.post(configData.SECUREURL + configData.ADMINSETTINGS, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Received admin settings data')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.usertypes instanceof Array) {
                    setUsertypes(res.data.usertypes);
                }
                if (res.data.userstatuses instanceof Array) {
                    setUserStatuses(res.data.userstatuses);
                }
            }
        })
    }, [])

    useEffect(() => {
        console.log('Trying to get user data');

        setLoading(true)
        setResult('');

        const data = {
            userid: userId
        };

        axios.post(configData.SECUREURL + configData.ADMINGETUSER, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('User data received')
            console.log(res.data)

            if (res.data instanceof Object) {
                setUsername(res.data.username)
                setEmail(res.data.email)
                if (res.data.biography != null) setBiography(res.data.biography)
                setSelectedtype(res.data.type)
                setSelectedStatus(res.data.status)
                setProfilepicture(res.data.profilepicture)
            }
        }).catch(() => {

        }).finally(() => {
            setLoading(false)
        })
    }, [])

    const handleUpdate = () => {
        console.log('Trying to update user');
        setResult('')
        const data = {
            userid: userId,
            username: username,
            useremail: email,
            status: selectedstatus,
            type: selectedtype
        };
        axios.post(configData.SECUREURL + configData.ADMINUPDATEUSER, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Update user data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setResult('Saved')
                }
                else setResult(res.data.data)
            }
        })
    }

    const handleDelete = () => {
        console.log('Trying to remove user');
        setResult('')

        const data = {
            userid: userId,
        };
        axios.post(configData.SECUREURL + configData.ADMINREMOVEUSER, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Remove user data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setResult('Saved')
                }
                else setResult(res.data.data)
            }
        })
    };

    const handleConfirmDelete = () => {
        console.log('Trying to delete profile');
        const data = {
            userid: userId
        };
        axios.post(configData.SECUREURL + configData.ADMINDELETEPROFILE, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Delete profile data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setResult('Account successfully removed')
                }
                else setResult(res.data.data)
            }
            setConfirmationOpen(false);
        }).catch(() => {
            setResult('Could not remove account')
        })
    };

    const handleCancelDelete = () => {
        setConfirmationOpen(false);
    };

    return (
        <div className='adminuseredit'>

            <div className='adminuseredit_profilepicwrapper'>
                {profilepicture && <img className='adminuseredit_profilepic' src={profilepicture} alt='Profile Picture' />}
            </div>

            <div className='adminuseredit_userid'>{userId}</div>

            <input
                className='adminuseredit_username'
                value={username}
                onChange={e => setUsername(e.target.value)}
            />

            <input
                className='adminuseredit_useremail'
                value={email}
                onChange={e => setEmail(e.target.value)}
            />

            <textarea
                className='adminuseredit_userbiography'
                value={biography}
                onChange={e => setBiography(e.target.value)}
                disabled
            />

            <select
                className='adminuseredit_usertype'
                value={selectedtype}
                onChange={e => setSelectedtype(e.target.value)}
            >
                {
                    usertypes instanceof Array && usertypes.map(
                        (usertype, index) =>
                            <option key={'usertype' + index} value={index}>{usertype}</option>
                    )
                }
            </select>

            <select
                className='adminuseredit_userstatus'
                value={selectedstatus}
                onChange={e => setSelectedStatus(e.target.value)}
            >
                {
                    userstatuses instanceof Array && userstatuses.map(
                        (userstatus, index) =>
                            <option key={'userstatus' + index} value={userstatus}>{userstatus}</option>
                    )
                }
            </select>

            <div className='adminuseredit_result'>{result && result}</div>

            <div className='adminuseredit_buttons'>
                <button
                    className='adminuseredit_buttonssave'
                    onClick={handleUpdate}
                >
                    Save
                </button>

                <button
                    className='adminuseredit_buttonsdelete'
                    onClick={handleDelete}
                >
                    Delete
                </button>
            </div>

            {
                confirmationOpen && (
                    <div className='adminuseredit_confirmationmodal'>
                        <div className='adminuseredit_confirmationmodaloverlay'>
                            <p className='adminuseredit_confirmationmodaltitle'>Are you sure you want to delete?</p>
                            <button className='adminuseredit_confirmationmodalyesbutton' onClick={handleConfirmDelete}>Yes</button>
                            <button className='adminuseredit_confirmationmodalnobutton' onClick={handleCancelDelete}>No</button>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default UserEdit;