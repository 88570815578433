import React from 'react';

import './FiltersBar.css';

//revision 20240417

function FiltersBar({ state, setState }) {
  const handleAgreement = (e) => {
    setState(state => ({ ...state, selectedagreement: e.target.value, selectedoffset: 0 }))
  };

  const handleType = (e) => {
    setState(state => ({ ...state, selectedtype: e.target.value, selectedoffset: 0 }))
  };

  return (
    <div className='filtersbar'>
      <div className='filtersbar_filter' key='filtersbaroption1'>
        <select
          className='filtersbar_select'
          value={state.selectedagreement}
          onChange={e => handleAgreement(e)}
          name='agreement'
        >
          <option className='filtersbar_option' value={0} name='All'>Agreements (All)</option>
          {
            state.agreements.map((data, index) =>
              <option key={'agreementoption' + index} className='filtersbar_option' value={data.id} name={data.agreement}>{data.agreement}</option>
            )
          }
        </select>
      </div>
      <div className='filtersbar_filter' key='filtersbaroption2'>
        <select
          className='filtersbar_select'
          value={state.selectedtype}
          onChange={e => handleType(e)}
          name='type'
        >
          <option className='filtersbar_option' value={0} name='All'>Types (All)</option>
          {
            state.types.map((data, index) =>
              <option key={'typeoption' + index} className='filtersbar_option' value={data.id} name={data.type}>{data.type}</option>
            )
          }
        </select>
      </div>
    </div>
  )
}

export default FiltersBar;