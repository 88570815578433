import React from 'react'
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import EditListing from './EditListing.js';

import Spinner from './Spinner.js';
import Profile from './Profile.js';

import './Listings.css';

function Listings({ state, setState }) {
    const history = useNavigate();

    const handleOpenListing = (listing) => {
        history(listing.id)
    }

    const handleExpandListing = (listing) => {
        setState(state => ({
            ...state,
            listings: state.listings.map(l =>
                l === listing ? { ...l, open: true } : l
            )
        }));
    }

    const handleLister = (userid) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Profile state={state} setState={setState} userid={userid} />, modaltype: 'small', modaltitle: 'Profile' }))
    }

    const handleSeeMore = (offset) => {
        setState(state => ({ ...state, selectedoffset: offset }))
    }

    const handleOptionsGuest = (listing) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <OptionsGuest state={state} setState={setState} listing={listing} />, modaltype: 'small', modaltitle: 'Options' }))
    }

    const handleOptionsAdmin = (listing) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <OptionsAdmin state={state} setState={setState} listing={listing} />, modaltype: 'small', modaltitle: 'Options' }))
    }

    const handleShare = async (listing) => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Check out this listing!',
                    text: 'I found this amazing listing that you might be interested in.',
                    url: listing.metatags.canonical,
                });
                console.log('Share successful!');
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            alert('Share functionality is not supported in your browser.');
        }
    };

    const handleEdit = (listingid) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditListing state={state} setState={setState} listingid={listingid} />, modaltype: 'large', modaltitle: 'Edit Listing' }))
    }

    function OptionsGuest({ listing }) {
        return (
            <div className='modal_buttonscontainer'>
                <div className='modal_button' onClick={() => handleShare(listing)}>Share Listing</div>
            </div >
        );
    }

    function OptionsAdmin({ listing }) {
        return (
            <div className='modal_buttonscontainer'>
                <div className='modal_button' onClick={() => handleShare(listing)}>Share Listing</div>
                <div className='modal_button' onClick={() => handleEdit(listing.id)}>Edit Listing</div>
            </div>
        )
    }

    return (
        <div className='listings'>
            <div className='listings_content'>
                {state.listingsloading == true && state.listings.length == 0 && < Spinner loading={true} size={30} position='fixed' />}
                {
                    state.listings.map((listing, index) => {
                        return (
                            <div
                                key={'listing' + index}
                                className={listing.new ? 'listings_listing listings_listingsnew' : 'listings_listing'}
                                offset={listing.offset}
                                remaining={listing.remaining}
                            >
                                <div className='listings_listinginfo'>
                                    <div className='listings_listinginfoleft'></div>
                                    <div className='listings_listinginfocenter'>
                                        <div className='listings_listinginfocentercontents'>
                                            <div
                                                className='listings_lister'
                                                onClick={() => handleLister(listing.listerid)}
                                            >
                                                {listing.listername}
                                            </div>
                                            <div className='listings_seperator'> · </div>
                                            <div className='listings_timestamp'>{moment(listing.timestamp * 1000).fromNow()}</div>
                                            <div className='listings_seperator'> · </div>
                                            <p className='listings_district'>{listing.district}</p>
                                            <div className='listings_seperator'> · </div>
                                            <p className='listings_community'>{listing.community}</p>
                                            <div className='listings_seperator'> · </div>
                                            <p className='listings_agreement'>{listing.agreement}</p>
                                            <div className='listings_seperator'> · </div>
                                            <p className='listings_type'>{listing.type}</p>
                                            <div className='listings_seperator'> · </div>
                                            <p className='listings_views'> ({listing.counter} Views)</p>
                                            {
                                                listing.new === 1 &&
                                                <span className='listings_newflag'>new</span>
                                            }
                                        </div>
                                    </div>
                                    <div className='listings_listinginforight'>
                                        {!state.user.usertype && <div className='listings_listinginforightoptionsbutton' onClick={() => handleOptionsGuest(listing)}>...</div>}
                                        {state.user.usertype == 2 && <div className='listings_listinginforightoptionsbutton' onClick={() => handleOptionsAdmin(listing)}>...</div>}
                                    </div>
                                </div>
                                {
                                    (!listing.open && listing.descriptionfiltered.length > 8) &&
                                    <>
                                        <div
                                            className='listings_listingdescription'
                                            onClick={() => handleOpenListing(listing)}
                                        >
                                            {
                                                listing.descriptionfiltered.slice(0, 8).map((line, key) => (
                                                    <div
                                                        key={'line' + key}
                                                        className={line.rtl === 1 ? 'listings_textdirectionalityright listings_textalignright' : 'listings_textdirectionalityleft listings_textalignleft'}
                                                    >
                                                        {line.data}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div
                                            className="listings_listingseemore"
                                            onClick={() => handleExpandListing(listing)}
                                        >
                                            See more
                                        </div>
                                    </>
                                }
                                {
                                    (listing.open || listing.descriptionfiltered.length <= 8) &&
                                    <div
                                        className='listings_listingdescription'
                                        onClick={() => handleOpenListing(listing)}
                                    >
                                        {
                                            listing.descriptionfiltered.map((line, key) => (
                                                <div
                                                    key={'line' + key}
                                                    className={line.rtl === 1 ? 'listings_textdirectionalityright listings_textalignright' : 'listings_textdirectionalityleft listings_textalignleft'}
                                                >
                                                    {line.data}
                                                </div>
                                            ))
                                        }
                                    </div>
                                }
                                <div className='listings_listingimages'>
                                    {
                                        listing.images instanceof Array && listing.images.slice(0, 2).map((image, index) =>
                                            <img
                                                className='listings_listingimage'
                                                key={'image' + listing.id + index}
                                                onClick={() => handleOpenListing(listing)}
                                                src={image}
                                                loading='lazy'
                                                alt=''
                                                width='300'
                                                height='300'
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        )
                    })
                }
                {
                    state.listings.length > 0 && state.listings[state.listings.length - 1].remaining > 0 &&
                    <div className='listings_seemorecontainer'>
                        <div
                            className='listings_seemore'
                            onClick={() => handleSeeMore(state.listings[state.listings.length - 1].offset)}
                            disabled={state.listingsloading}
                        >
                            See more {state.listingsloading && '(loading...)'}
                        </div>
                    </div>
                }
                {
                    state.listingsloading == false && state.listings.length == 0 &&
                    <div className='listings_nolistings'>
                        <div className='listings_nolistingstitle'>No Listings</div>
                        <div className='listings_nolistingsmessage'>TRY CHANGING FILTERS</div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Listings;