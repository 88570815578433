import React, { useState, useEffect, useRef } from 'react';

import './Communities.css';

// revision 20241105

function Communities({ state, setState }) {
    const [searchTerm, setSearchTerm] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const filteredCommunities = state.communities.filter(community =>
        community.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleCommunityClick = (community) => {
        setState(prevState => ({
            ...prevState,
            selectedcommunity: community.id,
            modalopen: false,
            modalcontent: null,
            modaltype: null
        }));
    };

    return (
        <div className="communities">
            <input
                type="text"
                placeholder="Search communities..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="communities_search"
            />

            <div className="communities_list">
                {filteredCommunities.map((community, index) => (
                    <div
                        key={'community' + index}
                        className={state.selectedcommunity == community.id ? 'communities_community communities_communityselected' : 'communities_community'}
                        onClick={() => handleCommunityClick(community)}
                    >
                        {community.name} ({community.no})
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Communities;