import React, { useEffect, useState } from 'react';
import axios from 'axios';
import configData from '../Config.json';

import './TypesEditor.css';

//Revision frontend 9-10-2023

function TypesEditor({ state, setState }) {
    const [typeseditor, setTypeseditor] = useState([]);
    const [change, setChange] = useState(false);

    const [propertytypemenu, setPropertytypemenu] = useState(null);

    useEffect(() => {
        console.log('Trying to get types');
        axios.post(configData.SECUREURL + configData.ADMINGETPROPERTYTYPES, {}, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Get types data received')
            console.log(res.data)
            if (res.data instanceof Array) {
                setTypeseditor(res.data)
            }
        })
    }, [change]);

    const handletogglemenu = (type) => {
        setPropertytypemenu(<TypesOptions type={type} />)
    }

    function TypesOptions({ type }) {
        return (
            <div className='typeeditor_optionscontainer'>
                <div className='typeeditor_optionscontent'>
                    <button className='typeeditor_optionbutton' onClick={() => setPropertytypemenu(<AddType />)}>Add new</button>
                    <button className='typeeditor_optionbutton' onClick={() => setPropertytypemenu(<EditType type={type} />)}>Edit</button>
                    <button className='typeeditor_optionbutton' onClick={() => setPropertytypemenu(<RemoveType type={type} />)}>Remove</button>
                    <button className='typeeditor_optionbutton' onClick={() => setPropertytypemenu(null)}>Cancel</button>
                </div>
            </div>
        )
    }

    function AddType() {
        const [typeid, setTypeid] = useState();
        const [typename, setTypename] = useState();

        const addType = (typeid, typename) => {
            console.log('Trying to add type');
            const data = {
                typeid: typeid,
                typename: typename
            };
            axios.post(configData.SECUREURL + configData.ADMINADDPROPERTYTYPE, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Add type data received')
                console.log(res.data)
                if (res.data.code === 1) {
                    setChange(!change);
                    setPropertytypemenu(null)
                }
            }).catch(() => {
            })
        }

        return (
            <div className='typeeditor_optionscontainer'>
                <div className='typeeditor_optionscontent'>
                    <p className='typeeditor_optiontitle'>Add new type</p>
                    <input className='typeeditor_input' type='text' value={typeid} onChange={e => setTypeid(e.target.value)} />
                    <input className='typeeditor_input' type='text' value={typename} onChange={e => setTypename(e.target.value)} />
                    <button className='typeeditor_optionbutton' onClick={() => addType(typeid, typename)}>Add</button>
                    <button className='typeeditor_optionbutton' onClick={() => setPropertytypemenu()}>Cancel</button>
                </div>
            </div>
        )
    }

    function EditType({ type }) {
        const [typeid, setTypeid] = useState(type.id);
        const [typename, setTypename] = useState(type.type);

        const updateType = (id, name) => {
            console.log('Trying to update type');
            const data = {
                typeid: id,
                typename: name
            };
            axios.post(configData.SECUREURL + configData.ADMINUPDATEPROPERTYTYPE, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Update type data received');
                console.log(res.data)
                if (res.data.code === 1) {
                    setPropertytypemenu(null)
                }
                setChange(!change)
            }).catch(() => {
            })
        }

        return (
            <div className='typeeditor_optionscontainer'>
                <div className='typeeditor_optionscontent'>
                    <p className='typeeditor_optiontitle'>Edit type</p>
                    <input className='typeeditor_input' type='text' value={typeid} onChange={e => setTypeid(e.target.value)} />
                    <input className='typeeditor_input' type='text' value={typename} onChange={e => setTypename(e.target.value)} />
                    <button className='typeeditor_optionbutton' onClick={() => updateType(typeid, typename)}>Save</button>
                    <button className='typeeditor_optionbutton' onClick={() => setPropertytypemenu()}>Cancel</button>
                </div>
            </div>
        )
    }

    function RemoveType({ type }) {
        const [typeid, setTypeid] = useState(type.id);

        const removeType = (typeid) => {
            console.log('Trying to remove type');
            const data = {
                typeid: typeid
            };
            axios.post(configData.SECUREURL + configData.ADMINREMOVEPROPERTYTYPE, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Remove type data received');
                console.log(res.data);
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setChange(!change)
                        setPropertytypemenu(null)
                    }
                }
            }).catch(() => {
            })
        }

        return (
            <div className='typeeditor_optionscontainer'>
                <div className='typeeditor_optionscontent'>
                    <p className='typeeditor_optiontitle'>Remove {type.type} ?</p>
                    <button className='typeeditor_optionbutton' onClick={() => removeType(typeid)}>Yes</button>
                    <button className='typeeditor_optionbutton' onClick={() => setPropertytypemenu()}>Cancel</button>
                </div>
            </div>
        )
    }

    return (
        <div className='typeeditor'>
            <table className='typeeditor_table'>
                <thead><tr><th>Id</th><th>Type</th><th>Option</th></tr></thead>
                <tbody>
                    {
                        typeseditor && typeseditor.map((type, index) =>
                            <tr key={'type' + index}>
                                <td>{type.id}</td>
                                <td>{type.type}</td>
                                <td><span className='typeeditor_optionsbutton' onClick={() => handletogglemenu(type)}>...</span></td>
                            </tr>
                        )
                    }
                </tbody>
            </table>

            {propertytypemenu}

        </div>
    )
}

export default TypesEditor;