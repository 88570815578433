import React from 'react';
import './Spinner.css';

function Spinner({ loading, size, position = 'absolute' }) {
  return (
    loading && (
      <div className="spinner_container" style={{ position: position }}>
        <div className="spinner_spinner" style={{ width: size, height: size }}></div>
      </div>
    )
  );
}

export default Spinner;