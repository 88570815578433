import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import countries_sectionone1 from './Assets/countries/countries_sectionone1.png';
import configData from './Config.json';
import Metatags from './Metatags.js';

import './Countries.css';

function Countries({ state, setState }) {

    const history = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleCountry = (country) => {
        setState(state => ({ ...state, selectedcountry: country.id }))
        setState(state => ({ ...state, selecteddistrict: country.defaultdistrict }))
        setState(state => ({ ...state, selectedcommunity: 0 }))
        setState(state => ({ ...state, selectedoffset: 0 }))
        history('/' + country.dir)
    }

    return (
        <div className='countries'>
            <Metatags
                title='SoukAkarat.com | Online real estate listings'
                description="SoukAkarat.com has listings for properties. Place a free ad. Find what you're looking for now!"
                keywords='buy, sell, rent, properties, arab countries, شراء, بيع, إيجار, عقارات, البلاد العربية'
                canonical={configData.SECUREURL}
                robots='index'
                image={configData.SECUREURL + '/' + configData.SEOIMAGE}
            />
            <div className='countries_section1'>
                <div className='countries_section1text1'>SoukAkarat.com</div>
                <div className='countries_section1text2'>سوق العقارات دوت كوم</div>
            </div>
            <div className='countries_section2'>
                <img
                    className='countries_section2logo'
                    src={countries_sectionone1}
                    alt='sectionone1'
                    loading='lazy'
                    height='1000'
                    width='1000'
                />
                <div className='countries_section2title'>
                    <h1 className='countries_section2title1'>Search Properties for Sale & Rent</h1>
                    <h1 className='countries_section2title2'>Electronic Real Estate platform in the Arab World</h1>
                </div>
            </div>
            <div className='countries_section3'>
                <div className='countries_section3countrycontainer'>
                    {
                        state.countries.map((country, index) =>
                            <div key={'country' + index} className='countries_section3countryitem' onClick={() => handleCountry(country)} >
                                <h3 className='countries_section3countryitemtext'>{country.name} ({country.count})</h3>
                            </div>
                        )
                    }
                </div>
            </div>
            <div className='countries_section4'>
                <h4 className='countries_section4title'>Online real estate listings network</h4>
                <p className='countries_section4text'>SoukAkarat is a comprehensive online real estate listings network that simplifies the process of finding a property. Our platform allows users to browse and search for properties with ease, making it easier than ever to find the perfect home or investment opportunity. What sets us apart is that we offer these services completely online and at no cost to our users. Whether you're a buyer, seller, or landlord, SoukAkarat has the resources you need to navigate the real estate market with confidence. <span className='countries_sectiontwohashtags'>#realEstate</span>  <span className='countries_sectiontwohashtags'>#listings</span>  <span className='countries_sectiontwohashtags'>#properties</span></p>
            </div>
            <div className='countries_section5'>
                <h4 className='countries_section5title'>Disclaimer</h4>
                <p className='countries_section5text'>It is important for users to exercise due diligence and verify the authenticity of any property they are interested in. This may include conducting thorough inspections, obtaining professional advice, and verifying ownership and legal documentation.</p>
                <p className='countries_section5textarabic'>يجب على المستخدمين ممارسة العناية الواجبة والتحقق من صحة أي عقار يهتمون به. وقد يشمل ذلك إجراء عملية تدقيق والتحقق من الملكية والوثائق القانونية.</p>
            </div>
            <div className='countries_footer'>
                <div className='countries_footercol'>
                    <h5>Social Pages</h5>
                    <div className='countries_footerLinkContainer'>
                        <Link className='countries_footerLink' to="https://www.facebook.com/soukakaratcom" target="_blank" rel="noopener noreferrer">Facebook</Link>
                    </div>
                    <div className='countries_footerLinkContainer'>
                        <Link className='countries_footerLink' to="https://www.linkedin.com/company/soukakarat" target="_blank" rel="noopener noreferrer">Linkedin</Link>
                    </div>
                    <div className='countries_footerLinkContainer'>
                        <Link className='countries_footerLink' to="https://www.instagram.com/soukakarat" target="_blank" rel="noopener noreferrer">Instagram</Link>
                    </div>
                </div>
                <div className='countries_footercol'>
                    <h5>Useful Links</h5>
                    <div className='countries_footerLinkContainer'>
                        <Link className='countries_footerLink' to='mailto:admin@soukakarat.com'>Email Admin</Link>
                    </div>
                    <div className='countries_footerLinkContainer'>
                        <Link className='countries_footerLink' to='https://play.google.com/store/apps/details?id=com.soukakarat'>Download Android App</Link>
                    </div>
                    <div className='countries_footerLinkContainer'>
                        <Link className='countries_footerLink' to='privacypolicy'>Privacy Policy</Link>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Countries;