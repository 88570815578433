import React, { useEffect, useState } from 'react';
import axios from 'axios';
import configData from './Config.json';
import Spinner from './Spinner.js';
import SpecsComponent from './SpecsComponent.js';
import FileUploader from './FileUploader.js';
import { serialize, isUnicode, findLocation, suggestLocation } from './Utils/text.js';

import './EditListing.css';

//Revision 6-5-2023 

function EditListing({ state, setState, listingid }) {

    const [confirmationmodal, setConfirmationmodal] = useState(false);

    const [media, setMedia] = useState([]);
    const [previewimages, setPreviewimages] = useState([]);
    const [imagesToRemove, setImagesToRemove] = useState([]);

    const [description, setDescription] = useState('');
    const [descriptiondirectionrtl, setDescriptiondirectionrtl] = useState(false);

    const [locations, setLocations] = useState([]);
    const [smartsearch, setSmartsearch] = useState('');
    const [smartsearchdirectionrtl, setSmartsearchdirectionrtl] = useState(false);
    const [smartsearchresult, setSmartsearchresult] = useState([]);
    const [smartsearchloading, setSmartsearchloading] = useState(false);
    const [searchTimeout, setSearchTimeout] = useState(null);

    const [district, setDistrict] = useState(0);
    const [communities, setCommunities] = useState([]);
    const [community, setCommunity] = useState(0);
    const [type, setType] = useState(0);
    const [agreement, setAgreement] = useState(1);

    const [moredetails, setMoreDetails] = useState(false);
    const [specs, setSpecs] = useState({});

    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    useEffect(() => {
        const selectedCountry = state.countries.find(country => country.id == state.selectedcountry);

        if (!selectedCountry) {
            console.error('Selected country not found');
            return;
        }

        const localStorageKey = selectedCountry.dir + 'keywords';
        const storedCountryDirKeywords = JSON.parse(localStorage.getItem(localStorageKey));

        if (storedCountryDirKeywords && (Date.now() - storedCountryDirKeywords.timestamp < 10 * 24 * 60 * 60 * 1000)) {
            setLocations(storedCountryDirKeywords.keywords)
        }
        else {
            const data = {
                countryid: state.selectedcountry,
            };

            axios.post(configData.SECUREURL + configData.GETLOCATIONS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then(res => {
                if (res.data instanceof Object) {
                    const newCountryDirKeywords = {
                        timestamp: Date.now(),
                        keywords: res.data.data
                    };
                    localStorage.setItem(selectedCountry.dir + 'keywords', JSON.stringify(newCountryDirKeywords));
                    setLocations(res.data.data)
                }
            }).catch(() => {
                console.error('Error fetching locations')
            })
        }
    }, []);

    useEffect(() => {
        setResult('');
        setLoading(true)
        const data = {
            countryid: state.selectedcountry,
            listingid: listingid
        };

        axios.post(configData.SECUREURL + configData.GETLISTING, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setPreviewimages(res.data.data.images)
                    setDescription(res.data.data.descriptionraw)
                    if (isUnicode(res.data.data.descriptionraw)) {
                        setDescriptiondirectionrtl(true)
                    }
                    else {
                        setDescriptiondirectionrtl(false)
                    }
                    setDistrict(parseInt(res.data.data.district))
                    let communitiesReceived = state.menu.filter((item) => {
                        return parseInt(item.id) === parseInt(res.data.data.district);
                    })
                    if (communitiesReceived.length > 0) {
                        setCommunities(communitiesReceived[0].communities);
                    }
                    setCommunity(res.data.data.community)
                    setAgreement(res.data.data.agreement)
                    setType(res.data.data.type)
                    setSpecs(res.data.data.specs)
                }
                else {
                    setResult(res.data.data)
                }
            }
        }).catch(() => {
            setResult('Error')
        }).finally(() => {
            setLoading(false)
        })
    }, [listingid, state.id])

    const handleUpdate = e => {
        e.preventDefault();
        setLoading(true);
        setResult(null);

        if (!state.user) {
            setResult('User not logged in');
            setLoading(false)
            return
        }
        if (!description) {
            setResult('Description Missing');
            setLoading(false)
            return
        }
        if (!district) {
            setResult('District Missing');
            setLoading(false)
            return
        }
        if (!community) {
            setResult('Community Missing');
            setLoading(false)
            return
        }

        const editlistingFormData = new FormData();
        editlistingFormData.append('countryid', state.selectedcountry);
        editlistingFormData.append('userid', state.user.userid);
        editlistingFormData.append('listingid', listingid);
        editlistingFormData.append('description', description);
        editlistingFormData.append('district', district);
        editlistingFormData.append('community', community);
        editlistingFormData.append('type', type);
        editlistingFormData.append('agreement', agreement);
        for (let i = 0; i < media.length; i++) {
            editlistingFormData.append('file' + i, media[i]);
        }
        editlistingFormData.append('imagestoremove', JSON.stringify(imagesToRemove));
        editlistingFormData.append('specs', JSON.stringify(specs));

        axios.post(configData.SECUREURL + configData.UPDATELISTING, editlistingFormData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            setResult(null);
            if (res.data.code === 1) {
                setResult(res.data.data)
            }
            else {
                setResult(res.data.data)
            }
        }).catch(() => {
            setResult('Could not update listing')
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleSearch = () => {
        if (smartsearch !== '') {
            setSmartsearchloading(true);
            let result = suggestLocation(smartsearch, locations)
            setSmartsearchresult(result);
            setSmartsearchloading(false);
        }
    }

    const handleSmartClick = (districtid, communityid) => {
        var selecteddistrict = state.menu.filter((item) => item.id === districtid)
        setCommunities(selecteddistrict[0].communities)
        setDistrict(districtid)
        setCommunity(communityid)
        setSmartsearch('')
        setSmartsearchresult([])
    }

    const handleConfirm = () => {
        setConfirmationmodal(false);
        handleDelete();
    };

    const handleCancelModal = () => {
        setConfirmationmodal(false);
    };

    const handleDelete = () => {
        setLoading(true);
        setResult(null);

        const data = {
            countryid: state.selectedcountry,
            listingid: listingid
        };
        axios.post(configData.SECUREURL + configData.REMOVELISTING, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setResult('Listing successfully removed')
                }
                else {
                    setResult('Could not remove Listing')
                }
            }
        }).catch(() => {
            setLoading(false)
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleDistrict = e => {
        setDistrict(parseInt(e.target.value));
        setCommunities(state.menu[e.target.selectedIndex].communities);
        setCommunity(state.menu[e.target.selectedIndex].communities[0]?.id)
    }

    const handleDescription = e => {
        setDescription(e.target.value)

        if (isUnicode(e.target.value)) {
            setDescriptiondirectionrtl(true)
        }
        else {
            setDescriptiondirectionrtl(false)
        }
    }

    const handleSmartsearch = e => {
        setSmartsearch(e.target.value)

        if (isUnicode(e.target.value)) {
            setSmartsearchdirectionrtl(true)
        }
        else {
            setSmartsearchdirectionrtl(false)
        }

        if (searchTimeout) {
            clearTimeout(searchTimeout)
        }

        setSearchTimeout(setTimeout(() => {
            handleSearch(e.target.value)
        }, 700))
    }

    const handleCancel = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null }));
    }

    return (
        <div className='editlisting'>
            <div className='editlisting_listingid'>Listing ID {listingid}</div>

            <FileUploader
                previewImages={previewimages}
                media={media}
                setMedia={setMedia}
                setImagesToRemove={setImagesToRemove}
            />

            <textarea
                className={descriptiondirectionrtl ? 'editlisting_textarea editlisting_rtl' : 'editlisting_textarea editlisting_ltr'}
                rows='7'
                maxLength='5000'
                value={description} onChange={e => handleDescription(e)}
                required
                spellCheck={false}
            />

            <input
                className={smartsearchdirectionrtl ? 'editlisting_input editlisting_rtl' : 'editlisting_input editlisting_ltr'}
                placeholder='Search'
                value={smartsearch}
                onChange={(e) => handleSmartsearch(e)}
                spellCheck={false}
            />

            <div className='editlisting_smartsearchcontainer'>
                <div className='editlisting_smartsearchloadingcontainer'>
                    <Spinner loading={smartsearchloading} size={30} />
                </div>

                {
                    smartsearchresult instanceof Array && smartsearchresult.map(
                        (smartsearchitem, index) =>
                            (<div key={'smartsearchitem' + index} className='editlisting_smartsearchitem' onClick={() => handleSmartClick(smartsearchitem.district, smartsearchitem.community)}>{smartsearchitem.keyword}, {smartsearchitem.districtname}, {smartsearchitem.communityname}</div>)
                    )
                }
            </div>

            <select className='editlisting_select' value={district} onChange={e => handleDistrict(e)}>
                {
                    state.menu.map(
                        (districtoption, index) =>
                            (<option key={'option' + index} value={districtoption.id}>{districtoption.name}</option>)
                    )
                }
            </select>
            <select className='editlisting_select' value={community} onChange={e => setCommunity(e.target.value)}>
                {
                    communities instanceof Array && communities.map(
                        (communityoption, index) =>
                            (<option key={'option' + index} value={communityoption.id}>{communityoption.name}</option>)
                    )
                }
            </select>
            <select className='editlisting_select' value={type} onChange={e => setType(e.target.value)} >
                {
                    state.types.map(
                        (propertytype, index) =>
                            (<option key={'option' + index} value={propertytype.id}>{propertytype.type}</option>)
                    )
                }
            </select>
            <select className='editlisting_select' value={agreement} onChange={e => setAgreement(e.target.value)}>
                {
                    state.agreements.map(
                        (propertyagreement, index) =>
                            (<option key={'option' + index} value={propertyagreement.id}>For {propertyagreement.agreement}</option>)
                    )
                }
            </select>

            <button className='editlisting_morelessbutton' onClick={() => setMoreDetails(!moredetails)}>{moredetails ? "Less" : "More"}</button>
            {moredetails && <SpecsComponent type={type} agreement={agreement} inputData={specs} outputData={(arg) => setSpecs(arg)} />}

            <div className='editlisting_resultcontainer'>
                {result && result}
            </div>

            <div className='editlisting_spinnercontainer'>
                <Spinner loading={loading} size={30} />
            </div>

            {
                confirmationmodal && (
                    <div className='editlisting_confirmationmodal'>
                        <p className='editlisting_confirmationtext'>Are you sure ?</p>
                        <button className='editlisting_confirmationbutton' onClick={handleConfirm}>Confirm</button>
                        <button className='editlisting_confirmationbutton' onClick={handleCancelModal}>Cancel</button>
                    </div>
                )
            }
            <button onClick={handleUpdate} className='editlisting_submitbutton' type='button'>Update</button>
            <button onClick={() => setConfirmationmodal(true)} className='editlisting_deletebutton'>Remove</button>
            <button onClick={handleCancel} className='editlisting_submitbutton' type='button'>Cancel</button>
        </div>
    )
}

export default EditListing;