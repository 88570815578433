import React, { useState } from 'react';

import './CopyButton.css';

const CopyButton = ({ title, text, url }) => {
    const [buttonText, setButtonText] = useState('Copy URL');

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(url);
            setButtonText('Copied');
        } catch (error) {
            console.error('Error copying URL to clipboard:', error);
        }
    };

    return (
        <button className="copybutton" onClick={handleCopy}>
            {buttonText}
        </button>
    );
};

export default CopyButton;