import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Comments from './Comments.js';

import axios from 'axios';
import configData from './Config.json';
import moment from 'moment';
import NoMatch from './NoMatch.js';

import Modal from './Modal.js';
import Spinner from './Spinner.js';
import Metatags from './Metatags.js';
import CopyButton from './CopyButton.js';
import Profile from './Profile.js';
import Header from './Header.js';
import MediaGallery from './MediaGallery.js';

import './Listing.css';

function Listing({ state, setState }) {
    const { countryname, listingid } = useParams();

    const [id, setId] = useState(0);
    const [listing, setListing] = useState({});
    const [listerid, setListerid] = useState('');
    const [lister, setLister] = useState('');
    const [images, setImages] = useState([]);
    const [metatags, setMetatags] = useState({ title: '', description: '' });
    const [filtered, setFiltered] = useState(true);
    const [socialmediacommunities, setSocialmediacommunities] = useState([]);

    const [descriptionfiltered, setDescriptionfiltered] = useState([]);
    const [descriptionunfiltered, setDescriptionunfiltered] = useState([]);

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    useEffect(() => {
        let countryId = null;

        if (listingid) {
            setState(state => ({ ...state, selectedlisting: listingid }));
        }

        if (countryname) {
            const filteredCountry = state.countries.filter(country => country.dir === countryname);
            if (filteredCountry.length > 0) {
                countryId = filteredCountry[0].id;
                setState(state => ({ ...state, selectedcountry: countryId }));
            }
        }

        setLoading(true);

        const data = {
            countryid: countryId,
            listingid: listingid
        };

        axios.post(configData.SECUREURL + configData.GETLISTING, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setError(false);
                    setListerid(res.data.data.listerid)
                    setLister(res.data.data.listername);
                    setListing(res.data.data);
                    setDescriptionfiltered(res.data.data.descriptionfiltered)
                    setDescriptionunfiltered(res.data.data.descriptionunfiltered);
                    if (res.data.data.images != null) setImages(res.data.data.images);
                    setMetatags(res.data.data.metatags);
                    setSocialmediacommunities(res.data.socialmediacommunities)
                }
                else if (res.data.code === 0) {
                    setError(true);
                    setResult(res.data.data);
                }
            }
            setLoading(false);
        }).catch((err) => {
            console.log(err);
            setError(true);
            setResult('Please check connection and try again');
        }).finally(() => {
            setLoading(false);
        });

    }, [countryname, listingid])

    const toggleNonfiltered = () => {
        setFiltered(false)
    }

    const handleLister = (userid) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Profile state={state} setState={setState} userid={userid} />, modaltype: 'small', modaltitle: 'Profile' }))
    }

    const handleImage = (images, index) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <MediaGallery state={state} setState={setState} images={images} index={index} />, modaltype: 'mediagallery', modaltitle: '' }))
    }

    function Disclaimer() {
        return (
            <div style={{ padding: '0.75rem', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f0f0f5', margin: '1rem 0' }}>
                <h4 style={{ color: '#555', fontSize: '1rem', marginBottom: '0.5rem' }}>Disclaimer</h4>
                <p style={{ color: '#666', fontSize: '0.85rem', fontStyle: 'italic', lineHeight: '1.4' }}>
                    Before making any real estate transaction, please ensure you verify the authenticity of the seller or renter. It’s essential to review all necessary paperwork, check the property’s ownership details, and confirm any permits or legal documentation. Conducting thorough research and verification will help you avoid potential scams or misunderstandings. Always consult with a legal professional or real estate advisor for added security.
                </p>
                <h4 style={{ color: '#555', fontSize: '1rem', marginTop: '1rem', marginBottom: '0.5rem' }}>تنويه</h4>
                <p style={{ color: '#666', fontSize: '0.85rem', fontStyle: 'italic', lineHeight: '1.4', direction: 'ltr' }}>
                    قبل إتمام أي عملية عقارية، يرجى التحقق من صحة هوية البائع أو المؤجر. من الضروري مراجعة جميع الأوراق المطلوبة، والتحقق من تفاصيل ملكية العقار، والتأكد من صحة أي تصاريح أو مستندات قانونية. إجراء بحث دقيق والتحقق من المعلومات يساعدك على تجنب عمليات الاحتيال أو سوء الفهم المحتمل. يُنصح دائمًا باستشارة محترف قانوني أو مستشار عقاري لضمان الأمان.
                </p>
            </div>
        );
    }

    return (
        <div className='listing'>
            <Header state={state} setState={setState} />

            {
                !loading &&
                <Metatags
                    title={metatags.title}
                    description={metatags.description}
                    canonical={metatags.canonical}
                    robots='index'
                    image={metatags.ogimage}
                />
            }
            {
                loading ?
                    <div className='listing_spinnercontainer'>
                        <Spinner size={30} color="#686769" loading={true} />
                    </div>
                    :
                    (error ?
                        <NoMatch reason={result} listingid={state.selectedlisting} />
                        :
                        (Object.keys(listing).length > 0 && <>
                            <div key='listing_info' className='listing_info'>
                                <div className='listing_cols'>
                                    <div className='listing_infocol1'>
                                        <span>Listing ID: {state.selectedlisting}</span>
                                    </div>
                                    <div className='listing_infocol2'>
                                        <span className='listing_infoauthor' onClick={() => handleLister(listerid)}>{lister}</span>
                                        <span> · </span>
                                        <span>{listing.timestamp && moment(listing.timestamp * 1000).fromNow()}</span>
                                        <span> · </span>
                                        <span>{listing.districtname}</span>
                                        <span> · </span>
                                        <span>{listing.communityname}</span>
                                        <span> · </span>
                                        <span>{listing.agreement === 1 ? 'Sale' : 'Rent'}</span>
                                        <span> · </span>
                                        <span>{listing.typename}</span>
                                        <span> · </span>
                                        <span>Viewed ({listing.counter} times)</span>
                                    </div>
                                    <div className='listing_infocol4'>
                                        <span className='listing_datetime'>{listing.timestamp && moment(listing.timestamp * 1000).format("MMMM Do YYYY, h:mm:ss a")}</span>
                                    </div>
                                </div>
                            </div>
                            <div key='listing_description' className="listing_description">
                                {
                                    filtered ?
                                        descriptionfiltered.map((line, key) => (
                                            <div
                                                key={'line' + key}
                                                className={line.rtl === 1 ? 'listings_textdirectionalityright listings_textalignright' : 'listings_textdirectionalityleft listings_textalignleft'}
                                            >
                                                {line.data}
                                            </div>
                                        ))
                                        :
                                        descriptionunfiltered.map((line, key) => (
                                            <div
                                                key={'line' + key}
                                                className={line.rtl === 1 ? 'listings_textdirectionalityright listings_textalignright' : 'listings_textdirectionalityleft listings_textalignleft'}
                                            >
                                                {line.data}
                                            </div>
                                        ))
                                }
                                {
                                    listing.phone &&
                                    <div
                                        className='listing_seephonenumbers'
                                        onClick={toggleNonfiltered}>See phone numbers
                                    </div>
                                }
                                {
                                    socialmediacommunities.map((link, index) => {
                                        const capitalizeFirstLetter = (string) => {
                                            return string.charAt(0).toUpperCase() + string.slice(1);
                                        };

                                        return (
                                            <div key={'link' + index}>
                                                <a href={link.url} target="_blank" rel="noopener noreferrer">
                                                    {capitalizeFirstLetter(link.type)}: {link.name || link.url}
                                                </a>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            {
                                listing.specs && listing.specs.length !== 0 &&
                                <div className='listing_tablecontainer'>
                                    <table className='listing_table'>
                                        <tbody>
                                            {
                                                (Object.entries(listing.specs).map(([key, value], index) => {
                                                    return (
                                                        <tr key={'tr' + index} className='listing_row'>
                                                            <td>{key}</td>
                                                            <td>{value}</td>
                                                        </tr>
                                                    );
                                                }))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }

                            <div className='listing_images'>
                                {
                                    images.map((image, index) =>
                                        <div
                                            className='listing_imagecontainer'
                                            key={'image' + index}
                                            onClick={() => handleImage(images, index)}
                                        >
                                            <img
                                                className='listing_image'
                                                src={image}
                                                loading='lazy'
                                                alt=''
                                            />
                                        </div>
                                    )
                                }
                            </div>

                            <div className='listing_optionssection'>
                                <CopyButton title={metatags.title} text={metatags.description} url={metatags.canonical} />
                            </div>

                            <Disclaimer />

                            <div className='listing_comments'>
                                <Comments state={state} setState={setState} />
                            </div>
                        </>)
                    )
            }

            <Modal state={state} setState={setState} />
        </div>
    )
}

export default Listing;