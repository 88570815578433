import React, { useState } from 'react';

import './SpecsComponent.css';

// need to change to key value data...
//size 'Size' ''

function SpecsComponent({ type, agreement, outputData }) {
    const [formValues, setFormValues] = useState({
        'Size': '',
        'Building Age': '',
        'Floor': '',
        'Bedrooms': '',
        'Bathrooms': '',
        'Livingrooms': '',
        'Salon': '',
        'Parking': '',
        'Furnished': '',
        'Road Access': '',
        'Water Access': '',
        'Price': '',
        'Price Per Unit': '',
        'Zone': '',
        'Floors': '',
        'Apartments': '',
        'Deposit': '',
        'Payment Method': '',
        'Availability': '',
        'Property Size': '',
        'Commercial Units': '',
        'Contact Number': '',
        'Reference Number': '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
        outputData({ ...formValues, [name]: value });
    };

    return (
        <form className='specscomponent_table' autoComplete='off'>
            {{
                1: <> {/*Apartment*/}
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Size</div>
                        <input className='specscomponent_spec' name='Size' value={formValues['Size']} onChange={handleChange} />
                    </div>
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Building Age</div>
                        <input className='specscomponent_spec' name='Building Age' value={formValues['Building Age']} onChange={handleChange} />
                    </div>
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Floor</div>
                        <input className='specscomponent_spec' name='Floor' value={formValues['Floor']} onChange={handleChange} />
                    </div>
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Bedrooms</div>
                        <input className='specscomponent_spec' name='Bedrooms' value={formValues['Bedrooms']} onChange={handleChange} />
                    </div>
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Bathrooms</div>
                        <input className='specscomponent_spec' name='Bathrooms' value={formValues['Bathrooms']} onChange={handleChange} />
                    </div>
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Livingrooms</div>
                        <input className='specscomponent_spec' name='Livingrooms' value={formValues['Livingrooms']} onChange={handleChange} />
                    </div>
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Salon</div>
                        <input className='specscomponent_spec' name='Salon' value={formValues['Salon']} onChange={handleChange} />
                    </div>
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Parking</div>
                        <input className='specscomponent_spec' name='Parking' value={formValues['Parking']} onChange={handleChange} />
                    </div>
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Furnished</div>
                        <input className='specscomponent_spec' name='Furnished' value={formValues['Furnished']} onChange={handleChange} />
                    </div>
                </>,
                2: <> {/*Land*/}
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Road Access</div>
                        <input className='specscomponent_spec' name='Road Access' value={formValues['Road Access']} onChange={handleChange} />
                    </div>
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Water Access</div>
                        <input className='specscomponent_spec' name='Water Access' value={formValues['Water Access']} onChange={handleChange} />
                    </div>
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Size</div>
                        <input className='specscomponent_spec' name='Size' value={formValues['Size']} onChange={handleChange} />
                    </div>
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Price/Unit</div>
                        <input className='specscomponent_spec' name='Price Per Unit' value={formValues['Price Per Unit']} onChange={handleChange} />
                    </div>
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Zone</div>
                        <input className='specscomponent_spec' name='Zone' value={formValues['Zone']} onChange={handleChange} />
                    </div>
                </>,
                3: <> {/*Villa*/}
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Property Size</div>
                        <input className='specscomponent_spec' name='Size' value={formValues['Size']} onChange={handleChange} />
                    </div>
                </>,
                4: <> {/*Store*/}
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Size</div>
                        <input className='specscomponent_spec' name='Size' value={formValues['Size']} onChange={handleChange} />
                    </div>
                </>,
                5: <> {/*Office*/}
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Size</div>
                        <input className='specscomponent_spec' name='Size' value={formValues['Size']} onChange={handleChange} />
                    </div>
                </>,
                6: <> {/*Hotel*/}
                </>,
                7: <> {/*Chalet*/}
                </>,
                8: <> {/*Building*/}
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Property Size</div>
                        <input className='specscomponent_spec' name='Property Size' value={formValues['Property Size']} onChange={handleChange} />
                    </div>
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Building Age</div>
                        <input className='specscomponent_spec' name='Building Age' value={formValues['Building Age']} onChange={handleChange} />
                    </div>
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>No of Floors</div>
                        <input className='specscomponent_spec' name='Floors' value={formValues['Floors']} onChange={handleChange} />
                    </div>
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>No of Apartments</div>
                        <input className='specscomponent_spec' name='Apartments' value={formValues['Apartments']} onChange={handleChange} />
                    </div>
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Commercial Units</div>
                        <input className='specscomponent_spec' name='Commercial Units' value={formValues['Commercial Units']} onChange={handleChange} />
                    </div>
                </>,
                9: <> {/*Institution*/}
                </>,
                10: <> {/*Warehouse*/}
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Size</div>
                        <input className='specscomponent_spec' name='Size' value={formValues['Size']} onChange={handleChange} />
                    </div>
                </>,
                11: <> {/*Offplan*/}
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Delivery Date</div>
                        <input className='specscomponent_spec' name='Delivery Date' value={formValues['Delivery Date']} onChange={handleChange} />
                    </div>
                </>,
                12: <> {/*Studio*/}
                </>,
                13: <> {/*Room*/}
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Size</div>
                        <input className='specscomponent_spec' name='Size' value={formValues['Size']} onChange={handleChange} />
                    </div>
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Furnished</div>
                        <input className='specscomponent_spec' name='Furnished' value={formValues['Furnished']} onChange={handleChange} />
                    </div>
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Bathrooms</div>
                        <input className='specscomponent_spec' name='Bathrooms' value={formValues['Bathrooms']} onChange={handleChange} />
                    </div>
                </>,
                14: <> {/*Partition*/}
                </>,
                15: <> {/*Penthouse*/}
                </>,
                18: <> {/*Duplex*/}
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Building Age</div>
                        <input className='specscomponent_spec' name='Building Age' value={formValues['Building Age']} onChange={handleChange} />
                    </div>
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Floors</div>
                        <input className='specscomponent_spec' name='Floors' value={formValues['Floors']} onChange={handleChange} />
                    </div>
                </>,
                19: <> {/*Townhouse*/}
                </>,
                20: <> {/*Bed Space*/}
                </>,
                21: <> {/*Sharing*/}
                </>,
                22: <> {/*Foyer*/}
                </>,
                23: <> {/*Cabin*/}
                </>,
                24: <> {/*Deluxe*/}
                </>,
            }[type]}
            {{
                1: <> {/*sale*/}

                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Price</div>
                        <input className='specscomponent_spec' name='Price' value={formValues['Price']} onChange={handleChange} />
                    </div>
                </>,
                2: <> {/*rent*/}
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Deposit</div>
                        <input className='specscomponent_spec' name='Deposit' value={formValues['Deposit']} onChange={handleChange} />
                    </div>
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Payment Method</div>
                        <input className='specscomponent_spec' name='Payment Method' value={formValues['Payment Method']} onChange={handleChange} />
                    </div>
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Availability</div>
                        <input className='specscomponent_spec' name='Availability' value={formValues['Availability']} onChange={handleChange} />
                    </div>
                    <div className='specscomponent_specrow'>
                        <div className='specscomponent_spec'>Price</div>
                        <input className='specscomponent_spec' name='Price' value={formValues['Price']} onChange={handleChange} />
                    </div>
                </>
            }[agreement]}
            <div className='specscomponent_specrow'>
                <div className='specscomponent_spec'>Reference Number</div>
                <input className='specscomponent_spec' name='Reference Number' value={formValues['Reference Number']} onChange={handleChange} />
            </div>
            <div className='specscomponent_specrow'>
                <div className='specscomponent_spec'>Contact Number</div>
                <input className='specscomponent_spec' name='Contact Number' value={formValues['Contact Number']} onChange={handleChange} />
            </div>
        </form>
    )
}

export default SpecsComponent