import React, { useEffect, useState } from 'react';
import axios from 'axios';
import configData from '../Config.json';

import './Announcements.css';

//Revision 9-29-2023

function Announcements({ state }) {
    const [announcements, setAnnouncements] = useState('');
    const [tryagain, setTryagain] = useState(false);

    const [result, setResult] = useState('');

    useEffect(() => {
        console.log('Trying to get announcements');
        const data = {
            countryid: state.id,
        };

        axios.post(configData.SECUREURL + configData.ADMINGETANNOUNCEMENTS, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Get announcements data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) setAnnouncements(res.data.data);
                else setResult(res.data.data)
            }
        }).catch(() => {
            setResult('Error')
        }).finally(()=>{

        })
    }, [tryagain]);

    const saveAnnouncement = () => {
        console.log('Trying to save announcements');
        const data = {
            countryid: state.id,
        };
        axios.post(configData.SECUREURL + configData.ADMINSAVEANNOUNCEMENTS, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Save announcements data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) setTryagain(!tryagain)
                else setResult(res.data.data)
            }
        }).catch(() => {
            setResult('Error')
        }).finally(()=>{
            
        })
    }

    return (
        <div className='annoucements'>
            <div>
                <textarea
                    className='annoucements_textarea'
                    value={announcements}
                    onChange={(e) => setAnnouncements(e.target.value)}
                />
            </div>

            <div className='annoucements_result'>{result && result}</div>

            <button className='annoucements_savebutton' onClick={() => saveAnnouncement()}>Save</button>
        </div>
    )
}

export default Announcements;