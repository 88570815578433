import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import configData from './Config.json';
import moment from 'moment';
import { isUnicode, hasUrl } from './Utils/text.js';
import Profile from './Profile.js';

import './Comments.css';

const COMMENTLIMIT = 500;
const REPLYLIMIT = 500;

// Revision 16-5-2023

function Comments({ state, setState }) {
    const history = useNavigate();

    const [commentsdata, setCommentsdata] = useState([]);
    const [change, setChange] = useState(false);

    const [newcomment, setNewcomment] = useState('');

    const [commentsshowmoreloading, setCommentsshowmoreloading] = useState(false);
    const [repliesshowmoreloading, setRepliesshowmoreloading] = useState(null);

    const [addcommentloading, setAddcommentloading] = useState(false);
    const [addcommentresult, setAddcommentresult] = useState(null);

    const openProfile = (userid) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Profile state={state} setState={setState} userid={userid} />, modaltype: 'small', modaltitle: 'Profile' }))
    }

    const handleLogin = () => {
        history('/authscreen')
    }

    const handleCancel = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }))
    }

    const handleAddReply = (commentid) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddReply commentid={commentid} />, modaltype: 'small', modaltitle: 'Add reply' }))
    }

    useEffect(() => {
        console.log('Trying to get comments');

        const data = {
            countryid: state.selectedcountry,
            listingid: state.selectedlisting,
            offset: 0
        };
        axios.post(configData.SECUREURL + configData.GETCOMMENTS, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log(res.data)
            if (res.data instanceof Array) {
                setCommentsdata(res.data);
            }
        })
    }, [change]);

    const showmorecomments = (offset) => {
        console.log('Trying to get more comments');

        setCommentsshowmoreloading(true)

        const data = {
            countryid: state.selectedcountry,
            listingid: state.selectedlisting,
            offset: offset
        };
        console.log(data)
        axios.post(configData.SECUREURL + configData.GETCOMMENTS, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log(res.data)
            if (res.data instanceof Array) {
                setCommentsdata([...commentsdata, ...res.data]);
            }
        }).catch((err) => {
        }).finally(() => {
            setCommentsshowmoreloading(false)
        })
    }

    const showmorereplies = (commentid, offset) => {
        console.log('Trying to get more replies');

        setRepliesshowmoreloading(commentid)

        const data = {
            countryid: state.selectedcountry,
            listingid: state.selectedlisting,
            commentid: commentid,
            offset: offset
        };

        axios.post(configData.SECUREURL + configData.GETREPLIES, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Show more replies data received')
            console.log(res.data)
            if (res.data instanceof Array) {
                setCommentsdata((prevCommentsData) => {
                    const updatedCommentsData = [...prevCommentsData];
                    const commentIndex = updatedCommentsData.findIndex(
                        (comment) => comment.id === commentid
                    );

                    if (commentIndex !== -1) {
                        updatedCommentsData[commentIndex].replies = [
                            ...(updatedCommentsData[commentIndex].replies || []),
                            ...res.data,
                        ];
                    }
                    return updatedCommentsData;
                });
            }
        }).catch((err) => {
        }).finally(() => {
            setRepliesshowmoreloading(null)
        })
    }

    const addComment = (newcomment) => {
        console.log('Trying to add comment');

        setAddcommentresult(null);

        if (!newcomment.length) {
            setAddcommentresult('please add comment')
            return
        }

        if (newcomment.length > COMMENTLIMIT) {
            setAddcommentresult('Exceeded character limit')
            return
        }

        if (hasUrl(newcomment)) {
            setAddcommentresult('URLs not allowed')
            return
        }

        setAddcommentloading(true)

        const data = {
            countryid: state.selectedcountry,
            listingid: state.selectedlisting,
            userid: state.user.userid,
            newcomment: newcomment
        };

        axios.post(configData.SECUREURL + configData.ADDCOMMENT, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Add comment data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setNewcomment('');
                    handleCancel();
                    setChange(!change);
                }
                else {
                    setAddcommentresult(res.data.data)
                }
            }
            else {
                setAddcommentresult('Error')
            }
        }).catch((err) => {
            setAddcommentresult(err.response?.data?.message || err.message || 'Network error');
        }).finally(() => {
            setAddcommentloading(false)
        })
    }

    const toggleCommentOptions = (commentid, commenttext) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <CommentOptions commentid={commentid} commenttext={commenttext} />, modaltype: 'small', modaltitle: 'Comment Options' }))
    }

    function CommentOptions({ commentid, commenttext }) {

        const handleEditComment = (commentid, commenttext) => {
            setState(state => ({ ...state, modalopen: true, modalcontent: <EditComment commentid={commentid} commenttext={commenttext} />, modaltype: 'small', modaltitle: 'Edit Comment' }))
        }

        const handleRemove = (commentid) => {
            setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveCommentConfirmation commentid={commentid} />, modaltype: 'small', modaltitle: 'Remove Comment' }))
        }

        return (
            <div className='modal_buttonscontainer'>
                <button className='modal_button' onClick={() => handleEditComment(commentid, commenttext)}>Edit</button>
                <button className='modal_button' onClick={() => handleRemove(commentid)}>Remove</button>
            </div>
        )
    }

    function EditComment({ commentid, commenttext }) {
        const edicommentTextArea = useRef(null);

        const [newcomment, setNewcomment] = useState(commenttext);
        const [editcommentloading, setEditcommentloading] = useState(false);
        const [editcommentresult, setEditcommentresult] = useState(null);

        useEffect(() => {
            const textArea = edicommentTextArea.current;
            textArea.focus();
            textArea.selectionStart = textArea.selectionEnd = textArea.value.length;
        }, []);

        const updateComment = (commentid, newcomment) => {
            console.log('Trying to update comment');

            setEditcommentresult(null)

            if (!newcomment.length) {
                setEditcommentresult('Please enter comment')
                return
            }

            if (newcomment.length > COMMENTLIMIT) {
                setEditcommentresult('Exceeded character limit')
                return
            }

            if (hasUrl(newcomment)) {
                setEditcommentresult('URLs not allowed')
                return
            }

            setEditcommentloading(true);

            const data = {
                countryid: state.selectedcountry,
                listingid: state.selectedlisting,
                userid: state.user.userid,
                commentid: commentid,
                newcomment: newcomment
            };
            axios.post(configData.SECUREURL + configData.UPDATECOMMENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Update comment data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        handleCancel();
                        setCommentsdata((prevCommentsData) => {
                            return prevCommentsData.map((comment) => {
                                if (comment.id === commentid) {
                                    return {
                                        ...comment,
                                        text: newcomment,
                                    };
                                }
                                return comment;
                            });
                        });
                    }
                    else {
                        setEditcommentresult(res.data.data)
                    }
                }
                else {
                    setEditcommentresult('Error');
                }
            }).catch((err) => {
                setEditcommentresult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setEditcommentloading(false)
            })
        }

        return (
            <div className='modal_buttonscontainer'>
                <textarea
                    className='editcomment_textarea'
                    value={newcomment}
                    onChange={e => setNewcomment(e.target.value)}
                    ref={edicommentTextArea}
                />
                <div className='comment_character_count'>
                    {newcomment.length}/{COMMENTLIMIT} characters
                </div>
                <div className='modal_result'>
                    {editcommentresult}
                </div>
                <button className='modal_button' disabled={editcommentloading} onClick={() => updateComment(commentid, newcomment)}>Save</button>
            </div>
        )
    }

    function RemoveCommentConfirmation({ commentid }) {
        const [removecommentloading, setRemovecommentloading] = useState(false);
        const [removecommentresult, setRemovecommentresult] = useState(null);

        const removeComment = (commentid) => {
            console.log('Trying to remove comment');

            setRemovecommentloading(true);

            const data = {
                countryid: state.selectedcountry,
                listingid: state.selectedlisting,
                userid: state.user.userid,
                commentid: commentid,
            };

            axios.post(configData.SECUREURL + configData.REMOVECOMMENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Remove comment data received');
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setNewcomment('');
                        handleCancel();
                        setCommentsdata((prevCommentsData) => {
                            const updatedCommentsData = prevCommentsData.filter((comment) => comment.id !== commentid);

                            if (updatedCommentsData.length > 0) {
                                const lastComment = updatedCommentsData[updatedCommentsData.length - 1];
                                lastComment.remaining = 0;
                            }

                            return updatedCommentsData;
                        });
                    }
                    else {
                        setRemovecommentresult(res.data.data);
                    }
                }
                else {
                    setRemovecommentresult('Error');
                }
            }).catch((err) => {
                setRemovecommentresult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setRemovecommentloading(false)
            })
        }

        return (
            <div className='modal_buttonscontainer'>
                <div className='modal_title'>Confirm ?</div>
                <div className='modal_result'>
                    {removecommentresult}
                </div>
                <button className='modal_button' disabled={removecommentloading} onClick={() => removeComment(commentid)}>Yes</button>
            </div>
        )
    }

    function RemoveReplyConfirmation({ commentid, replyid }) {
        const [removereplyloading, setRemovereplyloading] = useState(false);
        const [removereplyresult, setRemovereplyresult] = useState(null);

        const removeReply = (commentid, replyid) => {
            console.log('Trying to remove reply');
            setRemovereplyresult(null)

            const data = {
                countryid: state.selectedcountry,
                listingid: state.selectedlisting,
                userid: state.user.userid,
                commentid: commentid,
                replyid: replyid,
            };
            console.log(data)
            axios.post(configData.SECUREURL + configData.REMOVEREPLY, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Remove reply data received');
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        handleCancel();
                        setCommentsdata((prevCommentsData) => {
                            return prevCommentsData.map((comment) => {
                                if (comment.id === commentid) {
                                    const updatedReplies = comment.replies.filter((reply) => reply.id !== replyid);
                                    return {
                                        ...comment,
                                        replies: updatedReplies,
                                    };
                                }
                                return comment;
                            });
                        });
                    }
                    else {
                        setRemovereplyresult(res.data.data);
                    }
                }
                else {
                    setRemovereplyresult('Error');
                }
            }).catch((err) => {
                setRemovereplyresult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setRemovereplyloading(false)
            })
        }

        return (
            <div className='modal_buttonscontainer'>
                <div className='modal_title'>Confirm ?</div>
                <div className='modal_result'>
                    {removereplyresult}
                </div>
                <button className='modal_button' disabled={removereplyloading} onClick={() => removeReply(commentid, replyid)}>Yes</button>
                <button className='modal_button' onClick={handleCancel}>Cancel</button>
            </div>
        )
    }

    const toggleReplyOptions = (commentid, replyid, replytext) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ReplyOptions commentid={commentid} replyid={replyid} replytext={replytext} />, modaltype: 'small', modaltitle: 'Reply Options' }))
    }

    function ReplyOptions({ commentid, replyid, replytext }) {
        const handleEditReply = (commentid, replyid, replytext) => {
            setState(state => ({ ...state, modalopen: true, modalcontent: <EditReply commentid={commentid} replyid={replyid} replytext={replytext} />, modaltype: 'small', modaltitle: 'Edit Reply' }))
        }

        const handleRemoveReply = (commentid, replyid) => {
            setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveReplyConfirmation commentid={commentid} replyid={replyid} />, modaltype: 'small', modaltitle: 'Remove Reply' }))
        }

        return (
            <div className='modal_buttonscontainer'>
                <button className='modal_button' onClick={() => handleEditReply(commentid, replyid, replytext)}>Edit</button>
                <button className='modal_button' onClick={() => handleRemoveReply(commentid, replyid)}>Remove</button>
            </div>
        )
    }


    function AddReply({ commentid }) {
        const addreplyTextArea = useRef(null);

        const [newreply, setNewreply] = useState('');
        const [addreplyloading, setAddreplyloading] = useState(false);
        const [addreplyresult, setAddreplyresult] = useState(null);

        useEffect(() => {
            addreplyTextArea.current.focus();
        }, []);

        const addReply = (commentid, newreply) => {
            console.log('Trying to add reply');

            if (!newreply.length) {
                setAddreplyresult('Please enter reply')
                return
            }

            if (newreply.length > REPLYLIMIT) {
                setAddreplyresult('Exceeded character limit')
                return
            }

            setAddreplyloading(true)

            const data = {
                countryid: state.selectedcountry,
                listingid: state.selectedlisting,
                userid: state.user.userid,
                commentid: commentid,
                newreply: newreply
            };

            console.log(data)

            axios.post(configData.SECUREURL + configData.ADDREPLY, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Add reply data received');
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        handleCancel();
                        setChange(!change);
                    }
                    else {
                        setAddreplyresult(res.data.data);
                    }
                }
                else {
                    setAddreplyresult('Error')
                }
            }).catch((err) => {
                setAddreplyresult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setAddreplyloading(false)
            })
        }

        return (
            <div className='modal_buttonscontainer'>
                <textarea
                    className='commentreply_textarea'
                    rows="4"
                    value={newreply}
                    onChange={e => setNewreply(e.target.value)}
                    ref={addreplyTextArea}
                />
                <div className='modal_result'>
                    {addreplyresult}
                </div>
                <button className='modal_button' disabled={addreplyloading} onClick={() => addReply(commentid, newreply)}>Reply</button>
            </div>
        )
    }

    function EditReply({ commentid, replyid, replytext }) {
        const editreplyTextArea = useRef(null);

        const [editreply, setEditreply] = useState(replytext);
        const [editreplyloading, setEditreplyloading] = useState(false);
        const [editreplyresult, setEditreplyresult] = useState(null);

        useEffect(() => {
            const textArea = editreplyTextArea.current;
            textArea.focus();
            textArea.selectionStart = textArea.selectionEnd = textArea.value.length;
        }, []);

        const updateReply = (commentid, replyid, newreply) => {
            console.log('Trying to update reply');
            setEditreplyresult(null)

            if (!newreply.length) {
                setEditreplyresult('Please enter reply')
                return
            }

            if (newreply.length > REPLYLIMIT) {
                setEditreplyresult('Exceeded character limit')
                return
            }

            const data = {
                countryid: state.selectedcountry,
                listingid: state.selectedlisting,
                userid: state.user.userid,
                commentid: commentid,
                replyid: replyid,
                newreply: newreply
            };

            axios.post(configData.SECUREURL + configData.UPDATEREPLY, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Update reply data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        handleCancel();
                        setCommentsdata((prevCommentsData) => {
                            return prevCommentsData.map((comment) => {
                                if (comment.id === commentid) {
                                    const updatedReplies = comment.replies.map((reply) => {
                                        if (reply.id === replyid) {
                                            return {
                                                ...reply,
                                                text: newreply,
                                            };
                                        }
                                        return reply;
                                    });

                                    return {
                                        ...comment,
                                        replies: updatedReplies,
                                    };
                                }
                                return comment;
                            });
                        });
                    }
                    else {
                        setEditreplyresult(res.data.data);
                    }
                }
                else {
                    setEditreplyresult('Error')
                }
            }).catch((err) => {
                setEditreplyresult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setEditreplyloading(false)
            })
        }

        return (
            <div className='modal_buttonscontainer'>
                <textarea
                    className='editreply_textarea'
                    value={editreply}
                    onChange={e => setEditreply(e.target.value)}
                    ref={editreplyTextArea}
                />
                <div className='modal_result'>
                    {editreplyresult}
                </div>
                <button className='modal_button' disabled={editreplyloading} onClick={() => updateReply(commentid, replyid, editreply)}>Save</button>
            </div>
        )
    }

    return (
        <div className='comments'>
            {
                commentsdata.map((comment, indexcomment) =>
                (
                    <div key={'comment' + indexcomment} className='comments_commentcontainer'>
                        <div className='comments_comment'>
                            <div className='comments_commentleft'>
                                <div className='comments_commentleftrow'>
                                    <div className='comments_commentleftcolleft'>
                                        <div className='comments_commentavatarcontainer'>
                                            <img
                                                className='comments_commentavatar'
                                                src={comment.userprofilepicture}
                                                alt='comments_commentavatar'
                                                onClick={() => openProfile(comment.userid)}
                                            />
                                        </div>
                                    </div>
                                    <div className='comments_commentleftcolright'>
                                        <div className='comments_commentusernamecontainer'>
                                            <span className='comments_commentusername' onClick={() => openProfile(comment.userid)}>{comment.username}</span>
                                        </div>
                                        <div className={isUnicode(comment.text) ? 'comments_commenttextrtl' : 'comments_commenttextltr'}>{comment.text}</div>
                                        <div className='comments_commentreplycontainer'>
                                            <div className='comments_commentfromnow'>{comment.ts && moment(comment.ts * 1000).fromNow()}</div>
                                            {
                                                state.user && state.user.userid &&
                                                <button className='comments_replybutton' onClick={() => handleAddReply(comment.id)}>
                                                    <span>Reply</span>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='comments_commentright'>
                                {
                                    state.user && state.user.userid === comment.userid && (
                                        <div className='comments_commentoptionscontainer'>
                                            <button className='comments_commentoptionsbutton' onClick={() => toggleCommentOptions(comment.id, comment.text)}>
                                                ...
                                            </button>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        {
                            comment.replies && comment.replies.length > 0 &&
                            <div className='comments_replies'>
                                {
                                    comment.replies.map((reply, indexreply) =>
                                        <div key={'reply' + indexreply} className='comments_reply'>
                                            <div className='comments_replyleft'>
                                                <div className='comments_replyleftrow'>
                                                    <div className='comments_replyleftcolleft'>
                                                        <div className='comments_replyavatarcontainer'>
                                                            <img
                                                                className='comments_replyavatar'
                                                                src={reply.userprofilepicture}
                                                                alt='comments_replyavatar'
                                                                onClick={() => openProfile(reply.userid)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='comments_replyleftcolright'>
                                                        <div className='comments_replyusernamecontainer'>
                                                            <span className='comments_replyusername' onClick={() => openProfile(reply.userid)}>{reply.username}</span>
                                                        </div>
                                                        <div className={isUnicode(reply.text) ? 'comments_replytextrtl' : 'comments_replytextltr'}>{reply.text}</div>
                                                        <div className='comments_replyfromnow'>{reply.ts && moment(reply.ts * 1000).fromNow()}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='comments_replyright'>
                                                {
                                                    state.user && state.user.userid === reply.userid &&
                                                    <div className='comments_replyoptionscontainer'>
                                                        <div className='comments_replyoptions' onClick={() => toggleReplyOptions(comment.id, reply.id, reply.text)}>
                                                            ...
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    comment.replies[comment.replies.length - 1] && comment.replies[comment.replies.length - 1].remaining !== 0 &&
                                    <div className='comments_repliesshowmore'>
                                        <div className='comments_repliesshowmorebuttoncontainer'>
                                            <button className='comments_repliesshowmorebutton' onClick={() => showmorereplies(comment.id, comment.replies[comment.replies.length - 1].offset)}>show more ({comment.replies[comment.replies.length - 1].remaining}) {repliesshowmoreloading == comment.id && '(loading...)'}</button>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                ))
            }

            {
                commentsdata[commentsdata.length - 1] && commentsdata[commentsdata.length - 1].remaining !== 0 &&
                <div className='comments_commentsshowmore'>
                    <div className='comments_commentsshowmorebuttoncontainer'>
                        <button className='comments_commentsshowmorebutton' onClick={() => showmorecomments(commentsdata[commentsdata.length - 1].offset)}>show more ({commentsdata[commentsdata.length - 1].remaining}) {commentsshowmoreloading && '(loading...)'}</button>
                    </div>
                </div>
            }

            {
                state.user && state.user.userid ?
                    (
                        <div className='comments_loggedincontainer'>
                            {
                                state.user.userprofilepicture &&
                                <div className='comments_loggedincolumnsmall'>
                                    <div className='comments_loggedinavatarcontainer'>
                                        <img className='comments_loggedinavatar' src={state.user.userprofilepicture} alt="userIcon" />
                                    </div>
                                </div>
                            }
                            <div className='comments_loggedincolumnbig'>
                                <input
                                    className='comments_loggedininput'
                                    type="text"
                                    component="input"
                                    value={newcomment}
                                    onChange={e => setNewcomment(e.target.value)}
                                    dir="auto"
                                    onKeyDown={event => {
                                        if (event.key === 'Enter') {
                                            addComment(newcomment);
                                        }
                                    }
                                    }
                                />
                                <div className='comments_addcommentresult'>
                                    {addcommentresult}
                                </div>
                            </div>
                            <div className='comments_loggedincolumnsmall'>
                                <button className={newcomment.length > 0 ? 'comments_postbutton comments_postbuttonhighlight' : 'comments_postbutton'} type="button" disabled={addcommentloading} onClick={() => addComment(newcomment)}>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" t="1569683742680" viewBox="0 0 1024 1024" version="1.1" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><defs></defs><path d="M931.4 498.9L94.9 79.5c-3.4-1.7-7.3-2.1-11-1.2-8.5 2.1-13.8 10.7-11.7 19.3l86.2 352.2c1.3 5.3 5.2 9.6 10.4 11.3l147.7 50.7-147.6 50.7c-5.2 1.8-9.1 6-10.3 11.3L72.2 926.5c-0.9 3.7-0.5 7.6 1.2 10.9 3.9 7.9 13.5 11.1 21.5 7.2l836.5-417c3.1-1.5 5.6-4.1 7.2-7.1 3.9-8 0.7-17.6-7.2-21.6zM170.8 826.3l50.3-205.6 295.2-101.3c2.3-0.8 4.2-2.6 5-5 1.4-4.2-0.8-8.7-5-10.2L221.1 403 171 198.2l628 314.9-628.2 313.2z"></path></svg>
                                </button>
                            </div>
                        </div>
                    ) :
                    (
                        <div className='comments_loggedoutcontainer'>
                            <div className='comments_loggedoutcolumn'>
                                <div className='comments_loggedouttext'>Login to comment</div>
                            </div>
                            <div className='comments_loggedoutcolumnbig'>
                                <button className='comments_loginbutton' name="login" onClick={handleLogin}>Login</button>
                            </div>
                        </div>
                    )
            }
        </div>
    )
}

export default Comments;