import React from 'react';
import { Helmet } from 'react-helmet';

function Metatags({ title, description, keywords, canonical, robots, image }) {
    return (
        <Helmet>
            <title>{title}</title>
            {description && <meta name='description' content={description} />}
            {keywords && <meta name='keywords' content={keywords} />}
            <link rel='canonical' href={canonical} />
            <meta name='robots' content={robots} />

            <meta property="og:type" content='website' />
            <meta property="og:title" content={title} />
            <meta property="og:url" content={canonical} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:image:secure_url" content={image} />
            <meta property="og:site_name" content='soukakarat' />
            <meta property='og:locale' content='en_US' />
            <meta property='og:locale:alternate' content='ar' />

            <meta name="twitter:card" content='summary' />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
        </Helmet>
    )
}

export default Metatags;