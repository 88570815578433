import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import configData from '../Config.json';
import EditListing from '../EditListing.js';

import Spinner from '../Spinner';

import './ListingsEditor.css';

//Revision 6-5-2023 GPT assist

function ListingsEditor({ state, setState }) {
    const [search, setSearch] = useState('');
    const [listings, setListings] = useState([]);

    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState('');

    useEffect(() => {
        console.log('Trying to get listings data');

        setLoading(true);
        setResult('')

        const data = {
            countryid: state.selectedcountry,
            search: search
        };

        axios.post(configData.SECUREURL + configData.ADMINGETLISTINGS, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Listings data received');
            console.log(res.data);
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setListings(res.data.data);
                }
                else {
                    setListings([]);
                    setResult('Error')
                }
            }
            else {
                setListings([]);
                setResult('Error')
            }
        }).catch(() => {
            setResult('No connection')
        }).finally(() => {
            setLoading(false)
        })
    }, [search]);

    const handleEdit = (listingid) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditListing state={state} setState={setState} listingid={listingid} /> }))
    }

    return (
        <div className='listingseditor'>
            <div className='listingseditor_inputcontainer'>
                <input
                    className='listingseditor_input'
                    type='text'
                    value={search}
                    placeholder='Search by ID or Title'
                    onChange={e => setSearch(e.target.value)}
                />
            </div>

            <div className='adminmanagelistings_spinnercontainer'>
                <Spinner loading={loading} size={30} />
            </div>

            <div className='adminmanagelistings_result'>{result && result}</div>

            <table className='adminmanagelistings_table'>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Ts</th>
                        <th>Comm</th>
                        <th>Author</th>
                        <th>Status</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tbody>
                    {listings.length > 0 ? (
                        listings.map((listing, index) => (
                            <tr
                                key={'tr' + index}
                                className='adminmanagelistings_tablerow'
                                onClick={() => handleEdit(listing.id)}
                            >
                                <td>
                                    <span>{listing.id}</span>
                                </td>
                                <td>
                                    {moment(listing.timestamp * 1000).format('MMMM Do YYYY, h:mm:ss a')}
                                </td>
                                <td>{listing.community}</td>
                                <td>{listing.author}</td>
                                <td>{listing.status}</td>
                                <td>{listing.counter}</td>
                            </tr>
                        ))
                    )
                        :
                        (
                            <tr colSpan={6}>
                                <td>No listings</td>
                            </tr>
                        )}
                </tbody>
            </table>
        </div>
    )
}

export default ListingsEditor;