import React, { useRef, useEffect, useState } from 'react';

import axios from 'axios';
import configData from './Config.json';
import { findLocation } from './Utils/text';

import './SoukAkaratAI.css';

//Revision 30-4-2024

function SoukAkaratAI({ state, setState }) {
    const chatContainerRef = useRef(null);

    const [inputText, setInputText] = useState('');
    const [sendmessageloading, setSendmessageloading] = useState(false);

    const [chatHistory, setChatHistory] = useState([]);

    const [listinginfo, setListinginfo] = useState({});
    const [action, setAction] = useState('greet');

    const [locations, setLocations] = useState([]);

    const questions = {
        login: 'Please make sure to login.',
        greet: 'How can I help you today?',
        unwanted: 'Found unwanted data.',
        contactnumber: 'No contact number or link found.',
        spam: 'Spam detected.',
        hashtags: 'Hashtags not accepted.',
        agreement: 'No agreement found. Please mention Rent or Sale ?',
        type: 'No type found. Please mention type like Apartment / Room / Villa / Land / Studio ... ?',
        nolocation: 'Location not found in database.',
        foundlocations: 'More than 1 location found',
        confirm: 'The property looks good!',
        error: 'Couldn\'t connect to server'
    };

    useEffect(() => {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }, [chatHistory]);

    useEffect(() => {
        const selectedCountry = state.countries.find(country => country.id == state.selectedcountry);

        if (!selectedCountry) {
            console.error('Selected country not found');
            return;
        }

        const localStorageKey = selectedCountry.dir + 'keywords';
        const storedCountryDirKeywords = JSON.parse(localStorage.getItem(localStorageKey));

        if (storedCountryDirKeywords && (Date.now() - storedCountryDirKeywords.timestamp < 10 * 24 * 60 * 60 * 1000)) {
            setLocations(storedCountryDirKeywords.keywords)
        }
        else {
            const data = {
                countryid: state.selectedcountry,
            };

            axios.post(configData.SECUREURL + configData.GETLOCATIONS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then(res => {
                if (res.data instanceof Object) {
                    const newCountryDirKeywords = {
                        timestamp: Date.now(),
                        keywords: res.data.data
                    };
                    localStorage.setItem(selectedCountry.dir + 'keywords', JSON.stringify(newCountryDirKeywords));
                    setLocations(res.data.data)
                }
            }).catch(() => {
                console.error('Error fetching locations')
            })
        }
    }, []);

    const handleInputChange = (event) => {
        setInputText(event.target.value);
    };

    const handleSendMessage = () => {
        if (inputText == '') return;
        setSendmessageloading(true);

        setChatHistory((prevChatHistory) => [...prevChatHistory, { text: inputText, sender: 'user' }]);

        const data = {
            countryid: state.selectedcountry,
            text: inputText
        };

        axios.post(configData.SECUREURL + configData.GETBOTREPLY, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log(res.data)
            if (res.data instanceof Object) {
                const response = res.data;
                setListinginfo({ ...listinginfo, ...response });
            }
        }).catch(() => {
            setChatHistory(prevChatHistory => [...prevChatHistory, { text: questions.error, sender: 'ai' }]);
        }).finally(() => {
            setSendmessageloading(false)
        })

        setInputText('');
    };

    useEffect(() => {
        if (!state.user.userid) {
            setChatHistory(prevChatHistory => [...prevChatHistory, { text: questions.login, sender: 'ai' }]);
        }
        else if (action == 'greet') {
            setChatHistory(prevChatHistory => [...prevChatHistory, { text: questions.greet, sender: 'ai' }]);
            setAction('questions')
        }
        else if (listinginfo.unwanted?.sentences?.length) {
            setChatHistory(prevChatHistory => [...prevChatHistory, { text: questions.unwanted, sender: 'ai' }]);
        }
        else if (listinginfo.spam?.data.length) {
            setChatHistory(prevChatHistory => [...prevChatHistory, { text: questions.spam, sender: 'ai' }]);
        }
        else if (listinginfo.hashtags?.data.length) {
            setChatHistory(prevChatHistory => [...prevChatHistory, { text: questions.hashtags, sender: 'ai' }]);
        }
        else if (!listinginfo.contactnumber?.data.length) {
            if (!listinginfo.link?.data.length) {
                setChatHistory(prevChatHistory => [...prevChatHistory, { text: questions.contactnumber, sender: 'ai' }]);
            }
        }
        else if (
            !listinginfo.rent?.data.length &&
            !listinginfo.sale?.data.length
        ) {
            setChatHistory(prevChatHistory => [...prevChatHistory, { text: questions.agreement, sender: 'ai' }]);
        }
        else if (
            !listinginfo.apartment?.data.length &&
            !listinginfo.building?.data.length &&
            !listinginfo.duplex?.data.length &&
            !listinginfo.land?.data.length &&
            !listinginfo.office?.data.length &&
            !listinginfo.room?.data.length &&
            !listinginfo.studio?.data.length &&
            !listinginfo.villa?.data.length &&
            !listinginfo.warehouse?.data.length &&
            !listinginfo.offplan?.data.length &&
            !listinginfo.commercial?.data.length &&
            !listinginfo.store?.data.length
        ) {
            setChatHistory(prevChatHistory => [...prevChatHistory, { text: questions.type, sender: 'ai' }]);
        }
        else {
            let foundlocations = findLocation(listinginfo.inputtext.data, locations);
            if (foundlocations.length === 0) {
                setChatHistory(prevChatHistory => [...prevChatHistory, { text: questions.nolocation, sender: 'ai' }]);
            }
            else if (foundlocations.length == 1) {
                setChatHistory(prevChatHistory => [...prevChatHistory, { text: questions.confirm, sender: 'ai' }]);
                let keyword = foundlocations[0].keyword;
                let districtname = foundlocations[0].districtname;
                let communityname = foundlocations[0].communityname;
                setChatHistory(prevChatHistory => [...prevChatHistory, { text: 'Location found: ' + keyword + ', ' + districtname + ', ' + communityname, sender: 'ai' }]);
            }
            else if (foundlocations.length > 1) {
                const firstLocation = foundlocations[0];
                console.log(firstLocation)
                const sameDistrict = foundlocations.every(loc => loc.district === firstLocation.district);
                const sameCommunity = foundlocations.every(loc => loc.community === firstLocation.community);
                if (sameDistrict && sameCommunity) {
                    let keyword = firstLocation.keyword;
                    let districtname = firstLocation.districtname;
                    let communityname = firstLocation.communityname;
                    setChatHistory(prevChatHistory => [...prevChatHistory, { text: 'All locations are same, Location found: ' + keyword + ', ' + districtname + ', ' + communityname, sender: 'ai' }]);
                }
            }
            else {
                setChatHistory(prevChatHistory => [...prevChatHistory, { text: questions.foundlocations, sender: 'ai' }]);
                foundlocations.forEach(location => {
                    const { keyword, districtname, communityname } = location;
                    setChatHistory(prevChatHistory => [...prevChatHistory, { text: 'Location found: ' + keyword + ', ' + districtname + ', ' + communityname, sender: 'ai' }]);
                });
            }
        }
    }, [listinginfo]);

    return (
        <div className='soukakaratai'>
            <div ref={chatContainerRef} className='soukakaratai_chats'>
                {chatHistory.map((message, index) => (
                    <div key={index} className={message.sender === 'user' ? 'soukakaratai_chatuser' : 'soukakaratai_chatai'}>
                        <div>{message.text}</div>
                    </div>
                ))}
            </div>
            <div className='soukakaratai_inputgroup'>
                <textarea
                    className='soukakaratai_input'
                    value={inputText}
                    onChange={handleInputChange}
                    rows="3"
                    onKeyDown={(event) => {
                        if (event.key === 'Enter' && !event.shiftKey) {
                            event.preventDefault();
                            handleSendMessage();
                        }
                    }}
                />
                <button
                    className='soukakaratai_inputbutton'
                    onClick={handleSendMessage}
                    disabled={sendmessageloading}
                >
                    Send
                </button>
            </div>
        </div>
    );
}

export default SoukAkaratAI;