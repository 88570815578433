import React from 'react';
import { Link } from 'react-router-dom';
import SEO from './Metatags.js';

import './NoMatch.css';

function NoMatch({ reason, listingid }) {
  return (
    <div className='nomatch'>
      <SEO
        title='Not found'
        description='Looks like you found a page that does not exist'
        canonical={window.location.href}
        robots='noindex'
      />
      <div className='nomatch__title'>404</div>
      <div className='nomatch__caption'>Not Found</div>
      <h1 className='nomatch__text'>{reason}</h1>
      {
        listingid && <div className='nomatch__property'>LISTING ID: {listingid}</div>
      }
      <Link to='/'><button className='nomatch__homebutton'>Go Back Home</button></Link>
    </div>
  )
}

export default NoMatch