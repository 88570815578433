import React, { useState, useEffect } from 'react';

import './Credits.css';

const Credits = ({ state, setState }) => {
    const [credits, setCredits] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);

    useEffect(() => {
        setCredits(state.user.usercredits);
    }, [state.user.usercredits]);

    const creditPlans = [
        { credits: 1, price: 1 },
        { credits: 5, price: 5 },
        { credits: 10, price: 9 },
        { credits: 20, price: 17 },
        { credits: 50, price: 40 },
    ];

    const handleBuyCredits = (plan) => {
        setSelectedPlan(plan);
        setModalOpen(true);
    };

    return (
        <div className="credits">
            <div className='credits_currentcredits'>You have <strong>{credits}</strong> credits left.</div>

            <div className="credits_plans">
                {creditPlans.map((plan, index) => (
                    <div key={'plan' + index} className="credits_plan" onClick={() => handleBuyCredits(plan)}>
                        <p className='credits_plantitle'>{plan.credits} Credits</p>
                        <p className='credits_planprice'>${plan.price}</p>
                        <button className='credits_planbutton'>Buy Now</button>
                    </div>
                ))}
            </div>

            {
                selectedPlan && modalOpen && (
                    <div className="credits_modal">
                        <div className="credits_modalcontent">
                            <div className="credits_modalheader">
                                <h4>Checkout</h4>
                                <button className="credits_modalclosebutton" onClick={() => setModalOpen(false)}>×</button>
                            </div>
                            <div className="credits_modalbody">
                                <div className="credits_modaltitle">
                                    You are purchasing {selectedPlan.credits} credits for ${selectedPlan.price}.
                                </div>
                                <p>We are currently only accepting payments via cash.</p>
                                <div className="credits_options">
                                    <p>
                                        Option 1: <a
                                            href={`https://wa.me/96170617919?text=Hello,%20I%20would%20like%20to%20purchase%20${selectedPlan.credits}%20credits%20for%20${selectedPlan.price}%20USD.`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Send a message to purchase credits
                                        </a>
                                    </p>
                                    <p>
                                        Option 2: <a
                                            href="https://wa.me/96170617919"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Contact us on WhatsApp for more information
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

        </div>
    );
};

export default Credits;