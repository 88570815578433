import React from 'react';

import './Title.css';

function Title({ text, picture }) {
    return (
        <div className='title'>
            {
                picture && (
                    <div className='tite_picturecontainer'>
                        <img src={picture} alt="Title Icon" className='tite_picture' />
                    </div>
                )
            }
            <h1 className='titletext'>{text}</h1>
        </div>
    );
}

export default Title;