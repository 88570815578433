import React, { useEffect, useState } from 'react';
import axios from 'axios';
import configData from '../Config.json';

import './SocialMediaEditor.css';

function SocialMediaEditor({ state, setState }) {
    const [communities, setCommunities] = useState([]);
    const [links, setLinks] = useState([]);

    const [filteredlinks, setFilteredlinks] = useState([]);
    const [filterlinktype, setFilterlinktype] = useState(0);
    const [search, setSearch] = useState('');

    const [countries, setCountries] = useState(state.countries);
    const [linktypes, setLinktypes] = useState([{ value: 'telegram', string: 'Telegram' }, { value: 'facebook', string: 'Facebook' }]);
    const [types, setTypes] = useState(state.types);
    const [agreements, setAgreements] = useState(state.agreements);

    const [option, setOption] = useState(null);
    const [change, setChange] = useState(false);

    useEffect(() => {
        let filtered = links;
        if (filterlinktype !== 0) {
            filtered = filtered.filter(link => link.type === filterlinktype);
        }

        if (search !== '') {
            filtered = filtered.filter(link =>
                link.name.toLowerCase().includes(search.toLowerCase()) ||
                link.url.toLowerCase().includes(search.toLowerCase())
            );
        }

        setFilteredlinks(filtered);
    }, [links, filterlinktype, search]);

    useEffect(() => {
        console.log('Trying to get social media');
        const data = {
            countryid: state.selectedcountry,
        };

        axios.post(configData.SECUREURL + configData.ADMINGETSOCIALMEDIA, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            }
        }).then(res => {
            console.log('Get social media data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                setCommunities(res.data.communities);
                setLinks(res.data.links)
            }
        }).catch(() => {
            console.error('Error fetching social media')
        })
    }, [change]);

    function AddSocialMediaLink() {
        const [selectedtype, setSelectedtype] = useState('');
        const [url, setUrl] = useState('');
        const [name, setName] = useState('');
        const [chatid, setChatid] = useState('');

        const [result, setResult] = useState('');
        const [loading, setLoading] = useState(false);

        const addSocialMediaLink = (type, url, name, chatid) => {
            console.log('Trying to add social media link');
            setLoading(true);

            if (!type || !url || !name) {
                setLoading(false);
                setResult('No link')
                return
            }

            url = url.trim();
            name = name.trim();

            const data = {
                type: type,
                url: url,
                name: name,
                chatid: chatid
            };

            axios.post(configData.SECUREURL + configData.ADMINADDSOCIALMEDIALINK, data, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Add social media link data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code == 1) {
                        setChange(!change)
                        setOption(null)
                    }
                }
            }).catch(() => {

            }).finally(() => {
                setLoading(false)
            })
        }

        return (
            <div className='socialmediaeditor_modal'>
                <div className='socialmediaeditor_modalcontent'>
                    <div className='socialmediaeditor_modaltitle'>Add Social Media Link</div>
                    <select
                        className="socialmediaeditor_modalselect"
                        value={selectedtype}
                        onChange={(e) => setSelectedtype(e.target.value)}
                    >
                        <option value=''>Please select type</option>
                        {
                            linktypes.map((type, index) =>
                                <option key={'type' + index} value={type.value}>
                                    {type.string}
                                </option>
                            )
                        }
                    </select>
                    <input
                        className='smartsearch_modalinput'
                        type='text'
                        value={url}
                        onChange={e => setUrl(e.target.value)}
                        placeholder='URL'
                    />
                    <input
                        className='smartsearch_modalinput'
                        type='text'
                        value={name}
                        onChange={e => setName(e.target.value)}
                        placeholder='Group Name'
                    />
                    {
                        selectedtype == 'telegram' &&
                        <input
                            className='smartsearch_modalinput'
                            type='text'
                            value={chatid}
                            onChange={e => setChatid(e.target.value)}
                            placeholder='Chat Id'
                        />
                    }
                    <div>{result}</div>
                    <button className='smartsearch_modalbutton' onClick={() => addSocialMediaLink(selectedtype, url, name, chatid)} disabled={loading}>{loading ? 'Saving...' : 'Save'}</button>
                    <button className='smartsearch_modalbutton' onClick={() => setOption(null)} >Cancel</button>
                </div>
            </div>
        )
    }

    function EditSocialMediaLink({ socialmedialink }) {
        const [id, setId] = useState('');
        const [selectedtype, setSelectedtype] = useState('');
        const [url, setUrl] = useState('');
        const [name, setName] = useState('');
        const [chatid, setChatid] = useState('');

        const [result, setResult] = useState('');
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(socialmedialink.id)
            setSelectedtype(socialmedialink.type)
            setUrl(socialmedialink.url)
            setName(socialmedialink.name)
            setChatid(socialmedialink.chatid)
        }, [socialmedialink])

        const updateSocialMediaLink = (id, type, url, name, chatid) => {
            console.log('Trying to update social media link');
            setLoading(true)

            if (!type || !url || !name) {
                setLoading(false);
                setResult('No link')
                return
            }

            url = url.trim();
            name = name.trim();

            const data = {
                id: id,
                type: type,
                url: url,
                name: name,
                chatid: chatid
            };

            axios.post(configData.SECUREURL + configData.ADMINUPDATESOCIALMEDIALINK, data, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Update social media link data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code == 1) {
                        setChange(!change)
                        setOption(null)
                    }
                }
            })
        }

        return (
            <div className='socialmediaeditor_modal'>
                <div className='socialmediaeditor_modalcontent'>
                    <div className='socialmediaeditor_modaltitle'>Edit Social Media Link</div>
                    <select
                        className="socialmediaeditor_modalselect"
                        value={selectedtype}
                        onChange={(e) => setSelectedtype(e.target.value)}
                    >
                        <option value=''>Please select type</option>
                        {
                            linktypes.map((type, index) =>
                                <option key={'type' + index} value={type.value}>
                                    {type.string}
                                </option>
                            )
                        }
                    </select>
                    <input
                        className='smartsearch_modalinput'
                        type='text'
                        value={url}
                        onChange={e => setUrl(e.target.value)}
                        placeholder='URL'
                    />
                    <input
                        className='smartsearch_modalinput'
                        type='text'
                        value={name}
                        onChange={e => setName(e.target.value)}
                        placeholder='Group Name'
                    />
                    {
                        selectedtype == 'telegram' &&
                        <input
                            className='smartsearch_modalinput'
                            type='text'
                            value={chatid}
                            onChange={e => setChatid(e.target.value)}
                            placeholder='Chat Id'
                        />
                    }
                    <div>{result}</div>
                    <button className='smartsearch_modalbutton' onClick={() => updateSocialMediaLink(id, selectedtype, url, name, chatid)}>Update Keyword</button>
                    <button className='smartsearch_modalbutton' onClick={() => setOption(<RemoveSocialMediaLink socialmedialink={socialmedialink} />)}>Remove</button>
                    <button className='smartsearch_modalbutton' onClick={() => setOption()}>Cancel</button>
                </div>
            </div>
        )
    }

    function RemoveSocialMediaLink({ socialmedialink }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);

        useEffect(() => {
            setId(socialmedialink.id)
        }, [socialmedialink])

        const removeSocialMediaLink = (id) => {
            console.log('Trying to remove social media link');

            const data = {
                id: id
            };
            console.log(data)
            axios.post(configData.SECUREURL + configData.ADMINREMOVESOCIALMEDIALINK, data, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Remove social media link data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setChange(!change)
                        setOption(null)
                    }
                }
            }).catch((error) => {
                setResult(error)
            })
        }

        return (
            <div className='socialmediaeditor_modal'>
                <div className='socialmediaeditor_modalcontent'>
                    <p className='smartsearch_modaltitle'>Are you sure ?</p>
                    <div>{result}</div>
                    <button className='smartsearch_modalbutton' onClick={() => removeSocialMediaLink(id)}>Yes</button>
                    <button className='smartsearch_modalbutton' onClick={() => setOption(null)}>Cancel</button>
                </div>
            </div>
        )
    }

    function AddSocialMediaCommunity() {
        const [selectedcountry, setSelectedcountry] = useState('');
        const [menu, setMenu] = useState([]);
        const [selecteddistrict, setSelecteddistrict] = useState(0);
        const [communities, setCommunities] = useState([]);
        const [selectedcommunity, setSelectedcommunity] = useState(0);
        const [selectedtype, setSelectedtype] = useState(0);
        const [selectedagreement, setSelectedagreement] = useState(0);
        const [linkid, setLinkid] = useState('');
        const [broadcast, setBroadcast] = useState(false);

        const [result, setResult] = useState('');
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const data = {
                countryid: selectedcountry
            };
            axios.post(configData.SECUREURL + configData.GETMENU, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log(res.data)
                if (res.data instanceof Array) {
                    setMenu(res.data)
                }
            })
        }, [selectedcountry]);

        useEffect(() => {
            let foundCommunities = menu.find((district) => district.id == selecteddistrict)?.communities;
            if (foundCommunities instanceof Array) {
                setCommunities(foundCommunities)
            }
        }, [selecteddistrict]);

        const addSocialMediaCommunity = (country, district, community, type, agreement, linkid, broadcast) => {
            console.log('Trying to add social media community');

            if (country == '') {
                setResult('Please select country')
                return
            }
            setLoading(true);

            const data = {
                country: country,
                district: district,
                community: community,
                type: type,
                agreement: agreement,
                linkid: linkid,
                broadcast: broadcast
            };

            axios.post(configData.SECUREURL + configData.ADMINADDSOCIALMEDIACOMMUNITY, data, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Add social media community data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code == 1) {
                        setChange(!change)
                        setOption(null)
                    }
                }
            }).catch(() => {

            }).finally(() => {
                setLoading(false)
            })
        }

        return (
            <div className='socialmediaeditor_modal'>
                <div className='socialmediaeditor_modalcontent'>
                    <div className='socialmediaeditor_modaltitle'>Add Social Media Community</div>
                    <select
                        className="socialmediaeditor_modalselect"
                        value={selectedcountry}
                        onChange={(e) => setSelectedcountry(e.target.value)}
                    >
                        <option value=''>Please select country</option>
                        {
                            countries.map((country, index) =>
                                <option key={'country' + index} value={country.id}>
                                    {country.name}
                                </option>
                            )
                        }
                    </select>
                    <select
                        className="socialmediaeditor_modalselect"
                        value={selecteddistrict}
                        onChange={(e) => setSelecteddistrict(e.target.value)}
                    >
                        <option value={0}>All districts</option>
                        {
                            menu.map((district, index) =>
                                <option key={'district' + index} value={district.id}>
                                    {district.name}
                                </option>
                            )
                        }
                    </select>
                    <select
                        className="socialmediaeditor_modalselect"
                        value={selectedcommunity}
                        onChange={(e) => setSelectedcommunity(e.target.value)}
                    >
                        <option value={0}>All communities</option>
                        {
                            communities.map((community, index) =>
                                <option key={'community' + index} value={community.id}>
                                    {community.name}
                                </option>
                            )
                        }
                    </select>
                    <select
                        className="socialmediaeditor_modalselect"
                        value={selectedtype}
                        onChange={(e) => setSelectedtype(e.target.value)}
                    >
                        <option value={0}>All types</option>
                        {
                            types.map((type, index) =>
                                <option key={'type' + index} value={type.id}>
                                    {type.type}
                                </option>
                            )
                        }
                    </select>
                    <select
                        className="socialmediaeditor_modalselect"
                        value={selectedagreement}
                        onChange={(e) => setSelectedagreement(e.target.value)}
                    >
                        <option value={0}>All agreements</option>
                        {
                            agreements.map((agreement, index) =>
                                <option key={'agreement' + index} value={agreement.id}>
                                    {agreement.agreement}
                                </option>
                            )
                        }
                    </select>
                    <select
                        className="socialmediaeditor_modalselect"
                        value={linkid}
                        onChange={(e) => setLinkid(e.target.value)}
                    >
                        {
                            links.map((socialmedialink, index) =>
                                <option key={'socialmedialink' + index} value={socialmedialink.id}>
                                    {socialmedialink.name}
                                </option>
                            )
                        }
                    </select>
                    <select
                        className="socialmediaeditor_modalselect"
                        value={broadcast}
                        onChange={(e) => setBroadcast(e.target.value)}
                    >
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                    </select>
                    <div>{result}</div>
                    <button className='smartsearch_modalbutton' onClick={() => addSocialMediaCommunity(selectedcountry, selecteddistrict, selectedcommunity, selectedtype, selectedagreement, linkid, broadcast)} disabled={loading} >{loading ? 'Saving...' : 'Save'}</button>
                    <button className='smartsearch_modalbutton' onClick={() => setOption(null)} >Cancel</button>
                </div>
            </div>
        )
    }

    function EditSocialMediaCommunity({ community }) {
        const [id, setId] = useState('');

        const [selectedcountry, setSelectedcountry] = useState('');
        const [menu, setMenu] = useState([]);
        const [selecteddistrict, setSelecteddistrict] = useState(0);
        const [selectedcommunity, setSelectedcommunity] = useState(0);
        const [selectedtype, setSelectedtype] = useState(0);
        const [selectedagreement, setSelectedagreement] = useState(0);
        const [linkid, setLinkid] = useState('');
        const [broadcast, setBroadcast] = useState(false);

        const [result, setResult] = useState('');
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(community.id)
            setSelectedcountry(community.country)
            setSelecteddistrict(community.district)
            setSelectedcommunity(community.community)
            setSelectedtype(community.type)
            setSelectedagreement(community.agreement)
            setLinkid(community.linkid)
            setBroadcast(community.broadcast)
        }, [community])

        useEffect(() => {
            const data = {
                countryid: selectedcountry
            };
            axios.post(configData.SECUREURL + configData.GETMENU, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log(res.data)
                if (res.data instanceof Array) {
                    setMenu(res.data)
                }
            })
        }, [selectedcountry]);

        useEffect(() => {
            let foundCommunities = menu.find((district) => district.id == selecteddistrict)?.communities;
            if (foundCommunities instanceof Array) {
                setCommunities(foundCommunities)
            }
        }, [selecteddistrict]);

        const updateSocialMediaLink = (id, country, district, community, type, agreement, linkid, broadcast) => {
            console.log('Trying to update keyword');

            if (country == '') {
                setResult('Please select country')
                return
            }
            setLoading(true);

            const data = {
                id: id,
                country: country,
                district: district,
                community: community,
                type: type,
                agreement: agreement,
                linkid: linkid,
                broadcast: broadcast
            };

            axios.post(configData.SECUREURL + configData.ADMINUPDATESOCIALMEDIACOMMUNITY, data, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Update keyword data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code == 1) {
                        setChange(!change)
                        setOption(null)
                    }
                }
            }).catch(() => {

            }).finally(() => {
                setLoading(false)
            })
        }

        return (
            <div className='socialmediaeditor_modal'>
                <div className='socialmediaeditor_modalcontent'>
                    <div className='socialmediaeditor_modaltitle'>Edit Social Media Community</div>
                    <select
                        className="socialmediaeditor_modalselect"
                        value={selectedcountry}
                        onChange={(e) => setSelectedcountry(e.target.value)}
                    >
                        <option value=''>Please select country</option>
                        {
                            countries.map((country, index) =>
                                <option key={'country' + index} value={country.id}>
                                    {country.name}
                                </option>
                            )
                        }
                    </select>
                    <select
                        className="socialmediaeditor_modalselect"
                        value={selecteddistrict}
                        onChange={(e) => setSelecteddistrict(e.target.value)}
                    >
                        <option value={0}>All districts</option>
                        {
                            menu.map((district, index) =>
                                <option key={'district' + index} value={district.id}>
                                    {district.name}
                                </option>
                            )
                        }
                    </select>
                    <select
                        className="socialmediaeditor_modalselect"
                        value={selectedcommunity}
                        onChange={(e) => setSelectedcommunity(e.target.value)}
                    >
                        <option value={0}>All communities</option>
                        {
                            communities.map((community, index) =>
                                <option key={'community' + index} value={community.id}>
                                    {community.name}
                                </option>
                            )
                        }
                    </select>
                    <select
                        className="socialmediaeditor_modalselect"
                        value={selectedtype}
                        onChange={(e) => setSelectedtype(e.target.value)}
                    >
                        <option value={0}>All types</option>
                        {
                            types.map((type, index) =>
                                <option key={'type' + index} value={type.id}>
                                    {type.type}
                                </option>
                            )
                        }
                    </select>
                    <select
                        className="socialmediaeditor_modalselect"
                        value={selectedagreement}
                        onChange={(e) => setSelectedagreement(e.target.value)}
                    >
                        <option value={0}>All agreements</option>
                        {
                            agreements.map((agreement, index) =>
                                <option key={'agreement' + index} value={agreement.id}>
                                    {agreement.agreement}
                                </option>
                            )
                        }
                    </select>
                    <select
                        className="socialmediaeditor_modalselect"
                        value={linkid}
                        onChange={(e) => setLinkid(e.target.value)}
                    >
                        {
                            links.map((socialmedialink, index) =>
                                <option key={'socialmedialink' + index} value={socialmedialink.id}>
                                    {socialmedialink.name}
                                </option>
                            )
                        }
                    </select>
                    <select
                        className="socialmediaeditor_modalselect"
                        value={broadcast}
                        onChange={(e) => setBroadcast(e.target.value)}
                    >
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                    </select>
                    <div>{result}</div>
                    <button className='smartsearch_modalbutton' onClick={() => updateSocialMediaLink(id, selectedcountry, selecteddistrict, selectedcommunity, selectedtype, selectedagreement, linkid, broadcast)}>Update Keyword</button>
                    <button className='smartsearch_modalbutton' onClick={() => setOption(<RemoveSocialMediaCommunity community={community} />)}>Remove</button>
                    <button className='smartsearch_modalbutton' onClick={() => setOption()}>Cancel</button>
                </div>
            </div>
        )
    }

    function RemoveSocialMediaCommunity({ community }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);

        useEffect(() => {
            setId(community.id)
        }, [community])

        const removeSocialMediaCommunity = (id) => {
            console.log('Trying to remove social media community');

            const data = {
                id: id
            };

            axios.post(configData.SECUREURL + configData.ADMINREMOVESOCIALMEDIACOMMUNITY, data, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Remove social media community data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setChange(!change)
                        setOption(null)
                    }
                }
            }).catch((error) => {
                setResult(error)
            })
        }

        return (
            <div className='socialmediaeditor_modal'>
                <div className='socialmediaeditor_modalcontent'>
                    <p className='smartsearch_modaltitle'>Are you sure ?</p>
                    <div>{result}</div>
                    <button className='smartsearch_modalbutton' onClick={() => removeSocialMediaCommunity(id)}>Yes</button>
                    <button className='smartsearch_modalbutton' onClick={() => setOption(null)}>Cancel</button>
                </div>
            </div>
        )
    }

    return (
        <div className='socialmediaeditor'>
            <div className='socialmediaeditor_buttonscontainer'>
                <button className='socialmediaeditor_button' onClick={() => setOption(<AddSocialMediaLink />)}>Add Social Media Link</button>
                <button className='socialmediaeditor_button' onClick={() => setOption(<AddSocialMediaCommunity />)}>Add Social Media Community</button>
            </div>

            <div className='socialmediaeditor_buttonscontainer'>
                <select
                    className="socialmediaeditor_modalselect"
                    value={filterlinktype}
                    onChange={(e) => setFilterlinktype(e.target.value)}
                >
                    <option value='0'>All</option>
                    {
                        linktypes.map((type, index) =>
                            <option key={'type' + index} value={type.value}>
                                {type.string}
                            </option>
                        )
                    }
                </select>
                <input
                    className='smartsearch_modalinput'
                    type='text'
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    placeholder='Search'
                />
            </div>

            <table className='socialmediaeditor_table'>
                <thead>
                    <tr>
                        <th>Index</th>
                        <th>ID</th>
                        <th>Name</th>
                        <th>URL</th>
                        <th>Type</th>
                        <th>Chat Id</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredlinks.map((socialmedialink, index) => {
                            let comm = communities.filter(community =>
                                community.linkid === socialmedialink.id
                            );
                            return (
                                <React.Fragment key={'link' + index}>
                                    <tr className='socialmediaeditor_tablelink' onClick={() => setOption(<EditSocialMediaLink socialmedialink={socialmedialink} />)}>
                                        <td>{index + 1}</td>
                                        <td>{socialmedialink.id}</td>
                                        <td>{socialmedialink.name}</td>
                                        <td>{socialmedialink.url}</td>
                                        <td>{socialmedialink.type}</td>
                                        <td>{socialmedialink.chatid}</td>
                                        <td></td>
                                    </tr>
                                    {
                                        comm.map((community, index) =>
                                            <tr key={'community' + index} onClick={() => setOption(<EditSocialMediaCommunity community={community} />)}>
                                                <td></td>
                                                <td colSpan={5}>
                                                    <div className='socialmediaeditor_tablecommunity'>
                                                        <div>{community.id}</div>
                                                        <div>{state.countries.find((country) => country.id == community.country)?.name}</div>
                                                        <div>{community.district}</div>
                                                        <div>{community.community}</div>
                                                        <div>{community.type == 0 ? 'All types' : types.find((type) => type.id == community.type)?.type}</div>
                                                        <div>{community.agreement == 0 ? 'All agreements' : agreements.find((agreement) => agreement.id == community.agreement)?.agreement}</div>
                                                        <div>{community.broadcast ? 'Broadcast' : 'No broadcast'}</div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                </tbody>
            </table>

            {option}
        </div>
    )
}

export default SocialMediaEditor;